import { type GroupResponseDto, GroupType } from 'dtos';
import useSWR from 'swr';
import { getUrlSearchParams } from 'utils/client/fetch';
import type { PaginatedData, SortModel } from '../types/paginatedData';
import { fetcher } from '../utils/client/swr/jsonFetcher';

type useLeisureGroupsProps = {
  query?: string;
  page?: number;
  pageSize?: number;
  getAll?: boolean;
  sortModel?: SortModel;
};

const useLeisureGroups = ({
  query = '',
  page = 0,
  pageSize = 50,
  getAll,
  sortModel: { sort, sortDirection } = { sort: 'name', sortDirection: 'asc' },
}: useLeisureGroupsProps) => {
  const queryParams = getUrlSearchParams({
    type: GroupType.LEISURE,
    page: getAll ? 0 : page,
    pageSize: getAll ? 1000000 : pageSize,
    query,
    sort,
    sortDirection,
  });

  const {
    data: results,
    isValidating: isLoading,
    error,
    mutate: refresh,
  } = useSWR<PaginatedData<GroupResponseDto>, Error>(
    `groups?${queryParams.toString()}`,
    fetcher,
  );

  const { data, meta } = (results as PaginatedData<GroupResponseDto>) || {};

  return { data, meta, isLoading, error, refresh };
};

export default useLeisureGroups;
