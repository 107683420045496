import { Stack } from '@mui/material';
import T from 'components/common/T';
import { getEmailPhoneString } from 'utils/shared/formatting';
import type { ClientCreatableListItem, ClientListItem } from './types';

type ClientMultiselectOptionProps = {
  option: ClientListItem | ClientCreatableListItem;
  displayEmailAndPhone: boolean;
  showGroups: boolean;
};

const ClientMultiselectOption = ({
  option,
  displayEmailAndPhone,
  showGroups,
}: ClientMultiselectOptionProps) => (
  <Stack width="100%" pr={1}>
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      justifyContent="space-between"
      flexGrow={1}
    >
      <T>{option.name}</T>
      {displayEmailAndPhone &&
      getEmailPhoneString(option.email, option.phone) ? (
        <T variant="body2" color="text.secondary" ml="auto">
          {getEmailPhoneString(option.email, option.phone)}
        </T>
      ) : null}
    </Stack>
    {option?.groups && showGroups && (
      <T
        variant="body2"
        color="text.secondary"
        noWrap
        textOverflow="ellipsis"
        overflow="hidden"
      >
        {option.groups}
      </T>
    )}
  </Stack>
);

export default ClientMultiselectOption;
