import { type FormikErrors, type FormikValues, useFormikContext } from 'formik';
import type { SetStateAction } from 'react';
import type {
  FormikMetaWithError,
  FormikObjectError,
  UseFormikHelpers,
} from './types';

export const buildFullFieldName =
  <T>(namespace: string | undefined) =>
  (fieldName: T) =>
    namespace ? `${namespace}.${fieldName}` : fieldName;

export const getError = (
  meta: FormikMetaWithError,
  idField = 'id',
): string | undefined => {
  if (typeof meta?.error === 'object' && meta?.error !== null) {
    const e = meta.error as FormikObjectError;
    return e[idField] || e.id || e.name || '';
  } else {
    return meta.error as string;
  }
};

export function useTypedFormikContext<
  T extends FormikValues,
>(): UseFormikHelpers<T> & {
  isValid: boolean;
  errors: FormikErrors<T>;
  validateForm:
    | ((values: T) => void | object | Promise<FormikErrors<T>>)
    | undefined;
} {
  const {
    values: fv,
    setFieldValue,
    setValues,
    isValid,
    errors,
    submitCount,
    validateForm,
  } = useFormikContext<T>();

  return {
    values: fv,
    setFieldValue: (field, value, shouldValidate) =>
      setFieldValue(field as string, value, shouldValidate),
    setValues: (values, shouldValidate) =>
      setValues(values as SetStateAction<T>, shouldValidate),
    isValid,
    errors,
    submitCount,
    validateForm,
  };
}
