import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useState } from 'react';

interface TextInputDialogProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  textFieldLabel?: string;
  buttonText?: string;
  onSubmit: (value: string) => void;
}

const TextInputDialog = ({
  open,
  onClose,
  title = 'Enter a Name',
  textFieldLabel = 'Name',
  buttonText = 'Confirm',
  onSubmit,
}: TextInputDialogProps) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = () => {
    onSubmit(inputValue);
    setInputValue('');
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label={textFieldLabel}
          type="text"
          fullWidth
          variant="outlined"
          value={inputValue}
          onChange={handleInputChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={!inputValue}
        >
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TextInputDialog;
