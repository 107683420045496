import { debounce } from '@mui/material';
import { useEffect, useState } from 'react';
import { getClients } from 'requests/clients';
import {
  type ClientCreatableListItem,
  type ClientListItem,
  mapClientToClientListItem,
} from './types';

const useClientMultiselectClients = (initialQuery = '', delay = 200) => {
  const [options, setOptions] = useState<
    (ClientListItem | ClientCreatableListItem)[]
  >([]);

  useEffect(() => {
    const fetchClients = async () => {
      const clients = await getClients({
        sortModel: { sort: 'lastName', sortDirection: 'asc' },
        whatFor: 'select',
      });
      setOptions(clients.map(mapClientToClientListItem));
    };

    fetchClients();
  }, []);

  const debouncedFetch = debounce(async (query: string) => {
    const clients = await getClients({
      query,
      pageSize: 1000,
      sortModel: { sort: 'lastName', sortDirection: 'asc' },
      whatFor: 'select',
    });
    setOptions(clients.map(mapClientToClientListItem));
  }, delay);

  return { options, debouncedFetch };
};

export default useClientMultiselectClients;
