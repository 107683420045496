import { Add } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Chip,
  List,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  ListItem as MuiListItem,
  Stack,
  useTheme,
} from '@mui/material';
import Accordion, { accordionClasses } from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Tooltip from '@mui/material/Tooltip';
import T from 'components/common/T';
import type { ListItem } from 'components/types';

import type { GroupResponseDto } from 'dtos';
import { useState } from 'react';

type ClientMultiselectGroupAccordionProps = {
  group: GroupResponseDto;
  selectedClients: ListItem[];
  setSelectedClients: (selectedClients: ListItem[]) => void;
};

export default function ClientMultiselectGroupAccordion({
  group,
  selectedClients,
  setSelectedClients,
}: ClientMultiselectGroupAccordionProps) {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const toggleMemberSelection = (member: ListItem) => {
    if (selectedClients.some((client) => client.id === member.id)) {
      setSelectedClients(
        selectedClients.filter((client) => client.id !== member.id),
      );
    } else {
      setSelectedClients([...selectedClients, member]);
    }
  };

  return (
    <Accordion
      expanded={isOpen}
      onChange={toggleAccordion}
      disableGutters
      square
      elevation={0}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack direction="row" alignItems="center" width="100%" pr={1}>
          <Tooltip title={group.name} arrow>
            <T
              variant="body1"
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '250px',
              }}
            >
              {group.name}
            </T>
          </Tooltip>
          <T variant="body2" color="textSecondary">
            {group.members.length} members
          </T>
          <Chip
            variant="filled"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              const missingMembers = group.members.filter((member) =>
                selectedClients.every((c) => c.id !== member.id),
              );

              setSelectedClients([...selectedClients, ...missingMembers]);
            }}
            disabled={group.members.every((member) =>
              selectedClients.find((c) => c.id === member.id),
            )}
            icon={<Add />}
            label="add all"
            sx={{ ml: 'auto' }}
          />
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <List disablePadding>
          {group.members.map((member) => (
            <MuiListItem key={member.id} disableGutters disablePadding dense>
              <ListItemButton
                onClick={() => toggleMemberSelection(member)}
                selected={selectedClients.some((c) => c.id === member.id)}
              >
                <ListItemText primary={<T variant="body1">{member.name}</T>} />
                {(member.email || member.phone) && (
                  <ListItemSecondaryAction>
                    <T variant="body2" color="textSecondary">
                      {member.email} {member.email && member.phone && '|'}{' '}
                      {member.phone}
                    </T>
                  </ListItemSecondaryAction>
                )}
              </ListItemButton>
            </MuiListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
}
