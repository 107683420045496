import { type HmacResponseDto, TripResponseDto } from 'dtos';
import useSWR from 'swr';

import { fetcher } from '../utils/client/swr/jsonFetcher';

type UseTokenexHmacProps = {
  tokenScheme: 'PCI' | 'GUID';
};

export default function useTokenexHmac({ tokenScheme }: UseTokenexHmacProps) {
  const key = `public/tokenex/hmac?tokenScheme=${tokenScheme}`;

  const {
    data,
    isValidating: isLoading,
    error,
    mutate,
  } = useSWR<HmacResponseDto, Error>(key, fetcher);

  return {
    hmac: data?.hmac,
    timestamp: data?.timestamp,
    isLoading,
    error,
    refresh: mutate,
  };
}
