import {
  Autocomplete,
  Chip,
  Stack,
  type SxProps,
  TextField,
} from '@mui/material';
import T from 'components/common/T';
import { useField } from 'formik';
import { useAdvisors } from 'hooks';
import { useEffect, useState } from 'react';

type AdvisorListItem = {
  id: string;
  name: string;
  agencyUserId?: string;
  agencyName?: string;
};

type AdvisorMultiselectProps = {
  name: string;
  label: string;
  required?: boolean;
  onChange?: (value: AdvisorListItem[] | null) => void;
  disabled?: boolean;
  sx?: SxProps;
  includeAgencyName?: boolean;
  disableWhenEmpty?: boolean;
  endAdornment?: React.ReactNode;
};

const AdvisorMultiselect = ({
  name,
  label,
  required = false,
  onChange,
  disabled = false,
  sx,
  includeAgencyName = false,
  disableWhenEmpty = false,
  endAdornment,
}: AdvisorMultiselectProps) => {
  const [field, meta, helpers] = useField<AdvisorListItem[]>(name);
  const { data: advisors, isLoading } = useAdvisors({
    uniqueAdvisorsOnly: !includeAgencyName,
  });
  const [options, setOptions] = useState<AdvisorListItem[]>([]);

  useEffect(() => {
    if (isLoading) return;
    setOptions(
      advisors.map((advisor) => ({
        id: advisor.id,
        name: advisor.name,
        agencyUserId: advisor.agencyUserId,
        agencyName: advisor.agencyName,
      })),
    );
  }, [advisors, isLoading]);

  return (
    <Autocomplete
      {...field}
      sx={sx}
      fullWidth
      multiple
      options={options}
      disabled={disabled || (disableWhenEmpty && !options.length)}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(_event, selectedAdvisors) => {
        onChange?.(selectedAdvisors as AdvisorListItem[]);
        helpers.setValue(selectedAdvisors as AdvisorListItem[]);
      }}
      renderOption={(props, option) => (
        <li {...props} key={`${option.id}-${option.agencyName || ''}`}>
          {includeAgencyName ? (
            <Stack>
              <T variant="body1">{option.name}</T>
              <T variant="body2" color="text.secondary">
                {option.agencyName}
              </T>
            </Stack>
          ) : (
            option.name
          )}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          required={required}
          onFocus={() => helpers.setTouched(true)}
          onBlur={() => helpers.setTouched(false)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {params.InputProps.endAdornment}
                {endAdornment}
              </>
            ),
          }}
        />
      )}
      renderTags={(values, getTagProps) =>
        values.map((option, index) => (
          <Chip
            label={option.name}
            {...getTagProps({ index })}
            key={`${option.id}-${index}`}
          />
        ))
      }
    />
  );
};

export default AdvisorMultiselect;
