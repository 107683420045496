import { useMemo } from 'react';
import {
  type Iso3166Country,
  getCountryRegions,
  transformIso3166CountriesToList,
} from 'utils/server/addresses/countryUtils';

export function useIso3166Countries(selectedCountry?: string) {
  const countries = useMemo<Iso3166Country[]>(
    () => transformIso3166CountriesToList(),
    [],
  );

  const regions = useMemo(
    () => getCountryRegions(selectedCountry),
    [selectedCountry],
  );

  return {
    countries,
    regions,
  };
}
