import type {
  ClientGroupCreateRequestDto,
  ClientGroupResponseDto,
  ClientGroupUpdateRequestDto,
  GroupContactCreateRequestDto,
  GroupContactResponseDto,
  GroupContactUpdateRequestDto,
  GroupCreateRequestDto,
  GroupMemberResponseDto,
  GroupResponseDto,
  GroupUpdateRequestDto,
  TripCreateRequestDto,
  TripResponseDto,
  TripUpdateRequestDto,
} from 'dtos';
import type { PaginatedData } from 'types/paginatedData';
import { getUrlSearchParams } from 'utils/client/fetch';
import ky from 'utils/client/ky';

type GetCorporateGroupsProps = {
  page?: number;
  pageSize?: number;
  query?: string;
  queryOn?: string;
  sort?: string;
  sortDirection?: string;
  clientId?: string;
};

export const getCorporateGroups = async ({
  page = 0,
  pageSize = 500,
  queryOn = 'name',
  sort = 'name',
  sortDirection = 'asc',
  query = '',
  clientId,
}: GetCorporateGroupsProps) =>
  ky
    .get(
      `/api/groups?${getUrlSearchParams({
        query,
        queryOn,
        page,
        pageSize,
        sort,
        sortDirection,
        type: 'CORPORATE',
        clientId,
      }).toString()}`,
    )
    .json<PaginatedData<GroupResponseDto>>()
    .then((data) => data.data);

export const createCorporateClient = async (client: GroupCreateRequestDto) =>
  ky.post('/api/groups', { json: client }).json<GroupResponseDto>();

export const updateCorporateClient = async (
  id: string,
  client: GroupUpdateRequestDto,
) => ky.put(`/api/groups/${id}`, { json: client }).json<GroupResponseDto>();

export const updateCorporateClientNotes = async (id: string, notes: string) =>
  ky
    .put(`/api/groups/${id}/notes`, { json: { notes } })
    .json<GroupResponseDto>();

export const deleteCorporateClient = async (corporateClientId: string) =>
  ky.delete(`/api/groups/${corporateClientId}`).json<GroupResponseDto>();

export const createCorporateContact = async (
  contact: GroupContactCreateRequestDto,
  corporateClientId: string,
) =>
  ky
    .post(`/api/groups/${corporateClientId}/contacts`, { json: contact })
    .json<GroupContactResponseDto>();

export const updateCorporateContact = async (
  contact: GroupContactUpdateRequestDto,
  corporateClientId: string,
  contactId: string,
) =>
  ky
    .put(`/api/groups/${corporateClientId}/contacts/${contactId}`, {
      json: contact,
    })
    .json<GroupContactResponseDto>();

export const deleteCorporateContact = async (
  corporateClientId: string,
  contactId: string,
) =>
  ky
    .delete(`/api/groups/${corporateClientId}/contacts/${contactId}`)
    .json<GroupContactResponseDto>();

export const createGroup = async (group: GroupCreateRequestDto) =>
  ky.post('/api/groups', { json: group }).json<GroupResponseDto>();

export const updateGroup = async (
  groupId: string,
  group: GroupUpdateRequestDto,
) => ky.put(`/api/groups/${groupId}`, { json: group }).json<GroupResponseDto>();

export const deleteGroup = async (groupId: string) =>
  ky.delete(`/api/groups/${groupId}`).json<GroupResponseDto>();

export const addMemberToGroup = async (
  groupId: string,
  client: ClientGroupCreateRequestDto,
) =>
  ky
    .post(`/api/groups/${groupId}/clients`, { json: client })
    .json<GroupResponseDto>();

export const removeMemberFromGroup = async (
  groupId: string,
  clientId: string,
) => {
  await ky.delete(`/api/groups/${groupId}/clients/${clientId}`);
  return { status: 'ok' };
};

export const updateMemberInGroup = async (
  groupId: string,
  { id, isPrimary, relationship }: GroupMemberResponseDto,
) => {
  const updatedMemberData: ClientGroupUpdateRequestDto = {
    isPrimary,
    relationship,
  };

  return ky
    .put(`/api/groups/${groupId}/clients/${id}`, { json: updatedMemberData })
    .json<ClientGroupResponseDto>();
};

export const createCorporateProgram = async ({
  corporateProgram,
}: { corporateProgram: TripCreateRequestDto }) =>
  ky.post('/api/trips', { json: corporateProgram }).json<TripResponseDto>();

export const updateCorporateProgram = async ({
  updatedProgram,
  programId,
}: { updatedProgram: TripUpdateRequestDto; programId: string }) =>
  ky
    .put(`/api/trips/${programId}`, { json: updatedProgram })
    .json<TripResponseDto>();
