import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from '@mui/material';
import type { ClientGroupCreateRequestDto } from 'dtos';
import useClientGroups from 'hooks/useClientGroups';
import useLeisureGroups from 'hooks/useLeisureGroups';
import { useEffect, useRef, useState } from 'react';
import { addMemberToGroup, removeMemberFromGroup } from 'requests/groups';
import AllGroupsList from '../AllGroupsList';

type ClientGroupsDialogProps =
  | {
      clientId: string;
      open: boolean;
      onClose: () => void;
      clientSideOnly: true;
      defaultGroups: MinimalGroup[];
      setDefaultGroups: (groups: MinimalGroup[]) => void;
    }
  | {
      clientId: string;
      open: boolean;
      onClose: () => void;
      clientSideOnly?: false;
      defaultGroups?: never;
      setDefaultGroups?: never;
    };

export default function ClientGroupsDialog({
  clientId,
  open,
  onClose,
  clientSideOnly = false,
  defaultGroups,
  setDefaultGroups,
}: ClientGroupsDialogProps) {
  const { data: allGroups, refresh: refreshAllGroups } = useLeisureGroups({
    getAll: true,
  });

  const { data: clientGroups = [], refresh: refreshClientGroups } =
    useClientGroups({
      clientId,
    });

  const [search, setSearch] = useState('');
  const [filteredGroups, setFilteredGroups] = useState(allGroups);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (open && inputRef?.current) {
        inputRef.current.focus();
      }
    }, 100);
  }, [open]);

  const handleMemberAddition = async (
    groupId: string,
    client: ClientGroupCreateRequestDto,
  ) => {
    if (clientSideOnly) {
      const g = allGroups.find((g) => g.id === groupId) as MinimalGroup;
      return (setDefaultGroups as (groups: MinimalGroup[]) => void)([
        ...(defaultGroups as MinimalGroup[]),
        g,
      ]);
    }
    await addMemberToGroup(groupId, client);

    refreshAllGroups();
    refreshClientGroups();
  };

  const handleMemberRemoval = async (groupId: string, clientId: string) => {
    if (clientSideOnly)
      return (setDefaultGroups as (groups: MinimalGroup[]) => void)(
        (defaultGroups as MinimalGroup[]).filter((g) => g.id !== groupId),
      );

    await removeMemberFromGroup(groupId, clientId);

    refreshAllGroups();
    refreshClientGroups();
  };

  useEffect(() => {
    if (!open) {
      setSearch('');
    } else {
      setFilteredGroups(
        (allGroups || []).filter(
          (group) =>
            group.name.toLowerCase().includes(search.toLowerCase()) ||
            group.members.some((m) =>
              `${m.firstName} ${m.lastName}`
                .toLowerCase()
                .includes(search.toLowerCase()),
            ),
        ),
      );
    }
  }, [open, search, allGroups]);

  if (!filteredGroups) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Groups</DialogTitle>
      <Divider />
      <DialogContent sx={{ p: 3 }}>
        <TextField
          name="search"
          inputRef={inputRef}
          label="Search"
          variant="filled"
          fullWidth
          value={search}
          onChange={({ target }) => setSearch(target.value)}
          autoComplete="off"
          sx={{ pb: 2 }}
        />
        <AllGroupsList
          clientId={clientId}
          groups={filteredGroups as MinimalGroup[]}
          clientGroups={
            (clientSideOnly ? defaultGroups : clientGroups) as MinimalGroup[]
          }
          onAddToGroupClick={handleMemberAddition}
          onRemoveFromGroupClick={handleMemberRemoval}
          showPrimaryIndicator
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose}>Done</Button>
      </DialogActions>
    </Dialog>
  );
}
