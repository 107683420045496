import type { ListItem } from 'components/types';
import {
  type AgencyResponseDto,
  type ClientCreateRequestDto,
  type ClientProfileResponseDto,
  SourcedBy,
} from 'dtos';
import type { FormikValues } from 'formik';
import { type ExtendedFormSchema, createYupSchema } from 'utils/client/types';
import * as yup from 'yup';

export type ContextValues = {
  isCorporateEditable?: boolean;
  userAgencies?: AgencyResponseDto[];
  showGroupsSection?: boolean;
};

export enum FieldNames {
  AGENCY = 'agency',
  FIRST_NAME = 'firstName',
  MIDDLE_NAME = 'middleName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  PHONE = 'phone',
  SOURCED_BY = 'sourcedBy',
  IS_LINKED = 'isLinked',
  CORPORATE_GROUP = 'corporateGroup',
  ROLE = 'role',
  TITLE = 'title',
  DEPARTMENT = 'department',
  GROUPS = 'groups',
}

export interface ClientFormValues extends FormikValues {
  [FieldNames.AGENCY]: ListItem | null;
  [FieldNames.FIRST_NAME]: string;
  [FieldNames.MIDDLE_NAME]: string;
  [FieldNames.LAST_NAME]: string;
  [FieldNames.EMAIL]: string;
  [FieldNames.PHONE]: string;
  [FieldNames.SOURCED_BY]: SourcedBy;
  [FieldNames.IS_LINKED]?: boolean;
  [FieldNames.CORPORATE_GROUP]: ListItem | null;
  [FieldNames.ROLE]?: string;
  [FieldNames.TITLE]: string;
  [FieldNames.DEPARTMENT]: string;
  [FieldNames.GROUPS]: ListItem[];
}

const corporateGroupSchema = yup
  .object()
  .shape({
    id: yup.string(),
    name: yup.string(),
  })
  .typeError('Corporate Client is required')
  .label('Corporate Client');

const schema = ({ userAgencies = [] }: ContextValues) => {
  const baseAgencySchema = yup
    .object()
    .shape({
      id: yup.string(),
      name: yup.string(),
    })
    .label('Agency')
    .typeError('Agency is required');

  const agencySchema =
    userAgencies.length > 1
      ? baseAgencySchema.required()
      : baseAgencySchema.nullable();

  const yupSchema: Record<`${FieldNames}`, yup.AnySchema> = {
    agency: agencySchema,
    firstName: yup.string().required().nullable().label('First Name'),
    middleName: yup.string().nullable().label('Middle Name'),
    lastName: yup.string().required().nullable().label('Last Name'),
    email: yup.string().email().nullable().label('Email'),
    phone: yup.string().label('Phone'),
    sourcedBy: yup.mixed().oneOf(Object.values(SourcedBy)),
    isLinked: yup.boolean(),
    corporateGroup: yup.object().when('isLinked', {
      is: true,
      then: (schema) => corporateGroupSchema.required(),
      otherwise: (schema) => corporateGroupSchema.nullable(),
    }),
    role: yup.string().label('Role'),
    title: yup.string().label('Title'),
    department: yup.string().label('Department'),
    groups: yup.array().of(
      yup
        .object()
        .shape({
          id: yup.string().optional(), // 'id' is optional to allow for new groups
          name: yup.string().optional(), // 'name' is optional because existing groups have an id
        })
        .test(
          'id-or-name-required',
          'Either id or name is required',
          (value) => !!(value.id || value.name),
        ),
    ),
  };

  return createYupSchema<ClientFormValues>(yupSchema);
};

const getDefaultValues = (
  client:
    | ClientProfileResponseDto
    | Partial<ClientProfileResponseDto>
    | undefined,
  { userAgencies }: ContextValues,
): ClientFormValues => {
  const c = client;
  return {
    agency: c?.agencyId
      ? userAgencies?.find((agency) => agency.id === c.agencyId) || null
      : null,
    firstName: c?.firstName || '',
    middleName: c?.middleName || '',
    lastName: c?.lastName || '',
    email: c?.email || '',
    phone: c?.phone || '',
    sourcedBy: c?.sourcedBy || SourcedBy.ADVISOR,
    isLinked: !!c?.corporate?.corporateGroup?.id,
    corporateGroup: c?.corporate?.corporateGroup
      ? c.corporate?.corporateGroup
      : null,
    role: c?.corporate?.role || '',
    title: c?.corporate?.title || '',
    department: c?.corporate?.department || '',
    groups: [],
  };
};

const getEntityFromFormValues = (
  values: ClientFormValues,
): ClientCreateRequestDto => ({
  agencyId: values.agency?.id,
  firstName: values.firstName,
  middleName: values.middleName,
  lastName: values.lastName,
  email: values.email || undefined,
  phone: values.phone,
  sourcedBy: values.sourcedBy,
  corporateGroupId: values.corporateGroup?.id,
  corporateInfo: values.corporateGroup?.id
    ? {
        role: values.role || undefined,
        title: values.title,
        department: values.department,
      }
    : undefined,
  groups: values.groups.map((g) => ({ id: g.id, name: g.name })),
});

const formSchema: ExtendedFormSchema<
  ClientProfileResponseDto | Partial<ClientProfileResponseDto>,
  ClientCreateRequestDto,
  ClientFormValues,
  typeof schema,
  ContextValues
> = {
  schema,
  getDefaultValues,
  getEntityFromFormValues,
};

export default formSchema;
