import { CorporateFare, PersonOutlined } from '@mui/icons-material';
import { Stack, type SxProps, type Theme } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import AddClientDialog from 'components/clients/individual/AddClientDialog';
import T from 'components/common/T';
import type { CreatableListItem, ListItem } from 'components/types';
import type {
  ClientGroupResponseDto,
  ClientResponseDto,
  ClientWithGroupsResponseDto,
  GroupResponseDto,
} from 'dtos';
import { useField, useFormikContext } from 'formik';
import useDebouncedClientsAndCorporateGroups from 'hooks/useDebouncedClientsAndCorporateGroups';
import { queryTypes, useQueryStates } from 'next-usequerystate';
import { useState } from 'react';
import { getError } from 'utils/client/formik';
import type { FormikMetaWithError } from 'utils/client/types';
import { getEmailPhoneString } from 'utils/shared/formatting';

type ListItemType = 'client' | 'group';
export type ClientListItem = ListItem & {
  type: ListItemType;
  email?: string;
  phone?: string;
};

export type ClientListItemWithGroups = ClientListItem & {
  groups: ClientGroupResponseDto[];
};

type ClientCreatableListItem = CreatableListItem & {
  type?: ListItemType;
  email?: string;
  phone?: string;
};

const filter = createFilterOptions<
  ClientListItem | ClientCreatableListItem | ClientListItemWithGroups
>();

export type ClientSelectorProps<T extends string = string> = {
  allowAdd?: boolean;
  disabled?: boolean;
  helperText?: React.ReactNode;
  //field id is useful for repeated fields, so we can have multiple instances of the same field
  //ex: if name = client[someIndex], we need to know the name of the field in the schema for proper error messages
  fieldId?: string;
  label: string;
  name: T;
  onAddSuccess?: (newClient: ClientResponseDto) => void;
  onChange?: (value: ClientListItem | CreatableListItem | null) => void;
  required?: boolean;
  showGroups?: boolean;
  sx?: SxProps<Theme> | undefined;
  includeCorporateGroups?: boolean;
  value?: ClientListItem;
  displayEmailAndPhone?: boolean;
};

const mapClientToClientListItem = (
  client: ClientWithGroupsResponseDto,
): ClientListItemWithGroups => ({
  id: client.id,
  name: client.name,
  groups: client.groups,
  type: 'client',
  email: client.email,
  phone: client.phone,
});

const mapGroupToClientListItem = (
  group: GroupResponseDto,
): ClientListItemWithGroups => {
  return {
    id: group.id,
    name: group.name,
    groups: [],
    type: 'group',
  };
};

const ClientSelector = <T extends string = string>({
  allowAdd,
  disabled = false,
  helperText,
  fieldId = 'id',
  label,
  name,
  onAddSuccess,
  onChange,
  required = false,
  showGroups = false,
  sx,
  includeCorporateGroups = false,
  value,
  displayEmailAndPhone = false,
}: ClientSelectorProps<T>) => {
  const [field, meta, helpers] = useField<ClientListItem>(name);
  const { submitCount } = useFormikContext();

  const [options, setOptions] = useState<
    (
      | ClientListItem
      | ClientListItemWithGroups
      | (ClientCreatableListItem & { inputValue?: string })
    )[]
  >([]);

  const { setQuery } = useDebouncedClientsAndCorporateGroups({
    initialValue: '',
    includeCorporateGroups,
    callback: (clients, groups) => {
      const clientOptions = clients.map(mapClientToClientListItem);
      const groupOptions = groups.map(mapGroupToClientListItem);

      setOptions([...clientOptions, ...groupOptions]);
    },
  });

  const [addStatus, setAddStatus] = useQueryStates({
    addingClient: queryTypes.boolean.withDefault(false),
    clientName: queryTypes.string,
    fieldId: queryTypes.string,
  });

  const isDialogOpen = addStatus.addingClient && addStatus.fieldId === fieldId;

  const errorOrHelperText =
    (submitCount > 0 && getError(meta as FormikMetaWithError, fieldId)) ||
    helperText;

  return (
    <>
      <Autocomplete
        {...field}
        value={value ?? field.value}
        fullWidth
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        sx={sx}
        options={options}
        freeSolo={allowAdd}
        disabled={disabled}
        forcePopupIcon
        isOptionEqualToValue={(option, value) =>
          option.id === value.id ||
          (option as ClientCreatableListItem)?.inputValue === value?.name
        }
        onInputChange={async (_event, newInputValue) => {
          setQuery(newInputValue);
        }}
        onChange={(_event, selectedClient) => {
          const client = selectedClient as
            | ClientListItem
            | ClientCreatableListItem
            | null;

          const isAddingClient =
            typeof client === 'string' ||
            !!(client as ClientCreatableListItem)?.inputValue;

          if (isAddingClient)
            void setAddStatus({
              fieldId,
              addingClient: true,
              clientName:
                (client as ClientCreatableListItem)?.inputValue ||
                (client as unknown as string),
            });

          if (onChange) {
            const listItem = client
              ? ({
                  id: client.id,
                  name: client.name,
                  type: client.type,
                  groups: (client as ClientListItemWithGroups).groups || [],
                } as ClientListItem)
              : null;
            onChange(listItem);
          } else {
            if (!isAddingClient)
              helpers.setValue((selectedClient as ClientListItem) || null);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;

          // Suggest the creation of a new value
          const isExisting = options.some(
            (option) => inputValue === option.name,
          );
          if (allowAdd && inputValue !== '' && !isExisting) {
            filtered.push({
              id: '',
              inputValue,
              name: `Add "${inputValue}"`,
              groups: [],
            });
          }

          return filtered;
        }}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') return option;

          // Add "xxx" option created dynamically
          if ((option as ClientCreatableListItem)?.inputValue)
            return (option as ClientCreatableListItem).inputValue;

          // Regular option
          return option.name || '';
        }}
        renderOption={(props, option) => {
          let groupsString = '';

          if (
            (option as ClientListItemWithGroups).groups.length &&
            showGroups
          ) {
            const groups = (option as ClientListItemWithGroups).groups;

            groupsString = groups
              .filter((group) => !!group.group?.name)
              .map((group) => group.group.name)
              .join(', ');
          }

          return (
            <li {...props} key={option.id}>
              <Stack width="100%" pr={1}>
                <Stack direction="row" alignItems={'center'} spacing={1}>
                  {includeCorporateGroups && option.type === 'group' && (
                    <CorporateFare sx={{ color: 'text.secondary' }} />
                  )}
                  {includeCorporateGroups && option.type === 'client' && (
                    <PersonOutlined sx={{ color: 'text.secondary' }} />
                  )}
                  <Stack flexGrow={1}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={2}
                      justifyContent="space-between"
                      flexGrow={1}
                    >
                      <T>{option.name}</T>
                      {displayEmailAndPhone &&
                      getEmailPhoneString(option.email, option.phone) ? (
                        <Stack
                          direction="row"
                          spacing={1}
                          color="text.secondary"
                          ml="auto"
                        >
                          <T variant="body2">
                            {getEmailPhoneString(option.email, option.phone)}
                          </T>
                        </Stack>
                      ) : (
                        ''
                      )}
                    </Stack>

                    {groupsString && (
                      <T variant="body2" color="text.secondary" noWrap>
                        {groupsString}
                      </T>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            helperText={errorOrHelperText}
            InputProps={params.InputProps}
            label={label}
            required={required}
            onFocus={() => {
              helpers.setTouched(true);
            }}
            onBlur={() => {
              helpers.setTouched(false);
            }}
            error={
              submitCount > 0 &&
              !!getError(meta as FormikMetaWithError, fieldId)
            }
          />
        )}
      />
      {isDialogOpen && (
        <AddClientDialog
          entity={{
            //everything until the first space
            firstName: addStatus.clientName?.split(' ')[0] ?? '',
            //everything after the first space
            lastName: addStatus.clientName?.split(' ').slice(1).join(' ') ?? '',
          }}
          open={isDialogOpen}
          onClose={() =>
            void setAddStatus({
              fieldId: null,
              addingClient: null,
              clientName: null,
            })
          }
          onSuccess={(client) => {
            onAddSuccess
              ? onAddSuccess(client)
              : helpers.setValue({
                  id: client.id,
                  name: client.name,
                  type: 'client',
                });

            void setAddStatus({
              fieldId: null,
              addingClient: null,
              clientName: null,
            });
          }}
        />
      )}
    </>
  );
};

export default ClientSelector;
