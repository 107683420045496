import {
  LocalizationProvider,
  TimePicker as MuiTimePicker,
  type TimePickerProps,
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useField, useFormikContext } from 'formik';
import moment from 'moment-timezone';
import { useState } from 'react';

type CustomTimePickerProps<TDate, TName extends string = string> = Omit<
  TimePickerProps<TDate>,
  'onChange' | 'value' | 'renderInput' | 'open' | 'onClose' | 'onOpen'
> & {
  name: TName;
  onChange?: (newValue: TDate | null) => void;
  required?: boolean;
  labelWhenShrunk?: string;
  fullWidth?: boolean;
  helperText?: string;
  validateOnChange?: boolean;
};

function TimePicker<TDate, TName extends string = string>(
  props: CustomTimePickerProps<TDate, TName>,
) {
  const [field, meta, helpers] = useField(props.name);
  const { submitCount } = useFormikContext();
  const [open, setOpen] = useState(false);

  const { fullWidth: propFullWidth } = props;
  const fullWidth = typeof propFullWidth === 'undefined' ? true : propFullWidth;

  const value = field.value ? moment(field.value) : null;

  const error = props.validateOnChange
    ? !!meta.error
    : submitCount > 0 && !!meta.error;
  const helperText = props.validateOnChange
    ? meta.error ?? props.helperText
    : submitCount > 0
      ? meta.error
      : props.helperText;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MuiTimePicker
        {...props}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        value={value as TDate | null}
        timezone="UTC"
        onChange={(newValue: TDate | null) => {
          if (props.onChange) props.onChange(newValue);
          else helpers.setValue(newValue);
        }}
        slotProps={{
          popper: {
            placement: (fullWidth && 'bottom-end') || 'bottom',
          },
          textField: {
            name: props.name,
            fullWidth,
            required: props.required,
            error,
            helperText,
            onClick: () => setOpen(true),
          },
        }}
      />
    </LocalizationProvider>
  );
}

export default TimePicker;
