import {
  Autocomplete,
  Chip,
  type SxProps,
  TextField,
  type Theme,
  createFilterOptions,
} from '@mui/material';
import VirtualizedListbox from 'components/common/VirtualizedListbox';
import type {
  CreatableListItem,
  ListItem,
  SearchableListItem,
} from 'components/types';
import { useField, useFormikContext } from 'formik';
import { useDebounce, useDestinations } from 'hooks';
import {
  type HTMLAttributes,
  type JSXElementConstructor,
  type ReactElement,
  type Ref,
  forwardRef,
  useEffect,
  useState,
} from 'react';
import { getError } from 'utils/client/formik';
import type { FormikMetaWithError } from 'utils/client/types';

const filter = createFilterOptions<ListItem | CreatableListItem>();

type DestinationMultiselectProps<T extends string = string> = {
  name: T;
  label: string;
  idField?: string;
  onChange?: (
    value: Array<ListItem | CreatableListItem | SearchableListItem>,
  ) => void;
  disabled?: boolean;
  required?: boolean;
  sx?: SxProps<Theme> | undefined;
  helperText?: React.ReactNode;
};

type ListItemArray = ListItem[];

const DestinationListbox = forwardRef(function VirtualListbox(
  props: {
    children: ReactElement[];
  },
  ref: Ref<HTMLDivElement> | undefined,
) {
  return (
    <VirtualizedListbox {...props} ref={ref}>
      {[...props.children]}
    </VirtualizedListbox>
  );
});

const DestinationMultiselect = <T extends string = string>({
  disabled = false,
  helperText,
  idField = 'id',
  label,
  name,
  onChange,
  required = false,
  sx,
}: DestinationMultiselectProps<T>) => {
  const [field, meta, helpers] = useField<ListItemArray>(name);
  const { submitCount } = useFormikContext();
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const { destinations, isLoading } = useDestinations({
    query: debouncedSearchTerm,
    page: 0,
    pageSize: 100,
  });

  const errorOrHelperText =
    (submitCount > 0 && getError(meta as FormikMetaWithError, idField)) ||
    helperText;

  return (
    <Autocomplete
      {...field}
      fullWidth
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      sx={sx}
      options={destinations}
      disabled={disabled}
      multiple
      isOptionEqualToValue={(option, value) => option.id === value.id}
      loading={isLoading}
      onInputChange={(_event, value) => {
        setSearchTerm(value);
      }}
      onChange={(_event, newValue) => {
        const destinations = newValue as Array<ListItem | CreatableListItem>;
        const lastDestination = destinations[destinations.length - 1];

        if (!(lastDestination as CreatableListItem)?.inputValue)
          onChange?.(destinations || []);

        if (!onChange) helpers.setValue((newValue as ListItemArray) || null);
      }}
      filterOptions={(options) => options}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if ((option as CreatableListItem).inputValue) {
          return (option as CreatableListItem).inputValue;
        }
        return option.name || '';
      }}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          required={required}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <div className="MuiCircularProgress-root MuiCircularProgress-indeterminate" />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          error={
            submitCount > 0 && !!getError(meta as FormikMetaWithError, idField)
          }
          helperText={errorOrHelperText}
          onFocus={() => {
            helpers.setTouched(true);
          }}
          onBlur={() => {
            helpers.setTouched(false);
          }}
          placeholder="Type and press enter"
        />
      )}
      renderTags={(values, getTagProps) =>
        values.map((option, index) => (
          <Chip
            label={typeof option === 'string' ? option : option.name}
            {...getTagProps({ index })}
            key={option.id}
          />
        ))
      }
      ListboxComponent={
        DestinationListbox as unknown as JSXElementConstructor<
          HTMLAttributes<HTMLElement>
        >
      }
    />
  );
};

export default DestinationMultiselect;
