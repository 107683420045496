import {
  Button,
  Link,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  useTheme,
} from '@mui/material';

import NextLink from 'next/link';

import GroupAvatar from 'components/common/GroupAvatar';
import T from 'components/common/T';
import type { ClientGroupCreateRequestDto } from 'dtos';
import React from 'react';

type ClientGroupListItemProps = {
  clientId: string;
  group: MinimalGroup;
  isClientMember: boolean;
  showPrimaryIndicator?: boolean;
  showAddAndRemoveButtons?: boolean;
  onAddToGroupClick?: (
    groupId: string,
    client: ClientGroupCreateRequestDto,
  ) => void;
  onRemoveFromGroupClick?: (groupId: string, clientId: string) => void;
  onClick?: (groupId: string) => void;
  divider?: boolean;
};

export default function ClientGroupListItem({
  clientId,
  group,
  isClientMember,
  showPrimaryIndicator = false,
  showAddAndRemoveButtons = false,
  onAddToGroupClick,
  onRemoveFromGroupClick,
  divider = false,
  onClick,
}: ClientGroupListItemProps) {
  const theme = useTheme();

  //members will contain at most three people
  const membersToDisplay = group.members;
  const extraMemberCount = group.memberCount - membersToDisplay.length;

  //This components is used in two places, one where the user can click on the list item and one where they can't
  //If they can't click on the list item, we don't want to show the hover effect or ripple
  //Also, if they can't click on the list, we want to align the content to the horizontal edges of the list item

  return (
    <ListItem
      divider={divider}
      onClick={() => onClick?.(group.id)}
      disableGutters
      disablePadding
    >
      <ListItemButton
        disableRipple={!onClick}
        sx={{
          ...(!onClick && {
            px: 0,
            cursor: 'default',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }),
        }}
      >
        <ListItemIcon>
          <GroupAvatar name={group.name} color={group.color} />
        </ListItemIcon>
        <ListItemText
          primary={group.name}
          secondary={
            membersToDisplay.some((m) => m.firstName || m.lastName) && (
              <Stack component="span" direction="row">
                <T
                  variant="body2"
                  component="span"
                  color="text.primary"
                  whiteSpace="nowrap"
                >{`${
                  group.memberCount
                } Member${group.memberCount === 1 ? '' : 's'}`}</T>
                {group.memberCount > 0 && (
                  <>
                    <T variant="body2" component="span" mx={1}>
                      —
                    </T>
                    <Stack
                      direction="row"
                      flexWrap="wrap"
                      flex={1}
                      maxWidth="calc(100% - 200px)"
                    >
                      {membersToDisplay.map((m, idx) => (
                        <React.Fragment key={m.id}>
                          <Link
                            component={NextLink}
                            href={m.id ? `/clients/${m.id}` : '#'}
                            onClick={(e) => e.stopPropagation()}
                            style={{
                              textDecorationColor: theme.palette.text.secondary,
                              textDecoration: m.id ? 'underline' : 'none',
                              cursor: m.id ? 'cursor' : 'default',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            <T
                              variant="body2"
                              component="span"
                              color="text.secondary"
                            >
                              {m.firstName} {m.lastName}
                            </T>
                          </Link>
                          {(idx !== group.members.length - 1 ||
                            extraMemberCount > 0) && (
                            <T variant="body2" component="span" mr={0.5}>
                              ,
                            </T>
                          )}
                        </React.Fragment>
                      ))}
                      {extraMemberCount > 0 && (
                        <T
                          variant="body2"
                          component="span"
                          color="text.secondary"
                        >
                          + {extraMemberCount} More
                        </T>
                      )}
                    </Stack>
                  </>
                )}
              </Stack>
            )
          }
        />
        {/* if not clickable, then there's no hover effect.  in this case, right-align the secondary actions */}
        <ListItemSecondaryAction sx={{ ...(!onClick ? { right: 0 } : {}) }}>
          <Stack direction="row" spacing={1}>
            {showPrimaryIndicator && (
              <T variant="overline" color="text.secondary">
                Primary
              </T>
            )}
            {showAddAndRemoveButtons &&
              (isClientMember ? (
                <Button
                  size="small"
                  variant="text"
                  onClick={() => onRemoveFromGroupClick?.(group.id, clientId)}
                >
                  Remove
                </Button>
              ) : (
                <Button
                  size="small"
                  variant="contained"
                  disableElevation
                  onClick={() =>
                    onAddToGroupClick?.(group.id, {
                      id: clientId,
                      isPrimary: false,
                    })
                  }
                >
                  Add to Group
                </Button>
              ))}
          </Stack>
        </ListItemSecondaryAction>
      </ListItemButton>
    </ListItem>
  );
}
