import * as React from 'react';
import {
  type InputAttributes,
  type NumberFormatBaseProps,
  NumericFormat,
} from 'react-number-format';

interface CustomProps<T extends string = string> {
  onChange: (value: number) => void;
  name: T;
}

const PercentFormat = React.forwardRef<
  NumberFormatBaseProps<InputAttributes>,
  CustomProps
>(function NumberFormatCustom<T extends string = string>(
  props: CustomProps<T>,
  ref: React.Ref<unknown>,
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      decimalScale={2}
      getInputRef={ref}
      onValueChange={(values) => onChange(values.floatValue || 0)}
      thousandSeparator
      valueIsNumericString
      onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        // when user starts typing a number with a decimal, it will add a 0 in front of it then bounce the cursor to the start of the input
        // so we need to manually fix that here by catching the keydown event and manually moving the cursor
        if (
          event.key === '.' &&
          (!target.value || ['', '0'].includes(target.value))
        ) {
          target.value = '0.0';
          target.setSelectionRange(2, 2);
        }
        //if backspace pressed and value is 0 and cursor is at the start of the input, then clear the input
        // sometimes with react number format we end up in this situation and we need to manually fix it
        else if (
          event.key === 'Backspace' &&
          target.value === '0' &&
          target.selectionStart === 0
        ) {
          window.setTimeout(() => {
            target.value = '';
          }, 0);
        }
      }}
    />
  );
});

export default PercentFormat;
