import {
  type Breakpoint,
  Button,
  type ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  type TypographyProps,
} from '@mui/material';
import T from './T';

export interface ConformationDialogProps {
  title: string;
  message: string | JSX.Element;
  messageColor?: TypographyProps['color'];
  confirmButtonColor?: ButtonProps['color'];
  confirmButtonDisabled?: boolean;
  confirmButtonMessage: string;
  confirmButtonVariant?: ButtonProps['variant'];
  cancelButtonColor?: ButtonProps['color'];
  cancelButtonVariant?: ButtonProps['variant'];
  open: boolean;
  setOpen: (isOpen: boolean) => Promise<void> | void;
  onConfirm: () => Promise<void> | void;
  onCancel: () => Promise<void> | void;
  maxWidth?: false | Breakpoint | undefined;
  HeaderComponent?: JSX.Element;
}

export function ConfirmationDialog({
  title,
  message,
  messageColor,
  confirmButtonMessage,
  confirmButtonColor = 'error',
  confirmButtonVariant = 'contained',
  confirmButtonDisabled = false,
  cancelButtonColor = 'error',
  cancelButtonVariant = 'contained',
  open,
  setOpen,
  onConfirm,
  onCancel,
  maxWidth = 'xs',
  HeaderComponent,
}: ConformationDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={() => void setOpen(false)}
      fullWidth
      maxWidth={maxWidth}
    >
      {HeaderComponent ? HeaderComponent : undefined}
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <T variant="body1" color={messageColor}>
          {message}
        </T>
      </DialogContent>
      <DialogActions>
        <Button
          variant={cancelButtonVariant}
          color={cancelButtonColor}
          onClick={() => void onCancel()}
          disableElevation
        >
          Cancel
        </Button>
        <Button
          variant={confirmButtonVariant}
          color={confirmButtonColor}
          disabled={confirmButtonDisabled}
          onClick={() => void onConfirm()}
        >
          {confirmButtonMessage}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
