import {
  FormControlLabel as MuiFormControlLabel,
  Switch as MuiSwitch,
  type SwitchProps,
} from '@mui/material';
import { useField } from 'formik';

type CustomSwitchFieldProps<T extends string = string> = {
  name: T;
  label: string;
} & Omit<SwitchProps, 'name'>;

const Switch = <T extends string>(props: CustomSwitchFieldProps<T>) => {
  const { name, label } = props;
  const [field] = useField(name);

  return (
    <MuiFormControlLabel
      sx={{ mx: 0, ...props.sx, width: 'fit-content' }}
      disabled={props.disabled}
      control={<MuiSwitch {...field} {...props} checked={field.value} />}
      value={field.value}
      label={label}
    />
  );
};

export default Switch;
