import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LoopOutlinedIcon from '@mui/icons-material/LoopOutlined';
import {
  Button,
  ButtonGroup,
  FormControl,
  InputAdornment,
} from '@mui/material';
import type { ExchangeRateDto } from 'dtos/exchange-rates';
import { useField } from 'formik';
import { useState } from 'react';
import { getExchangeRate } from 'requests/exchangeRates';
import ConversionRateFormat from './ConversionRateFormat';
import MoneyFormat from './MoneyFormat';
import TextField from './TextField';

interface ButtonAdornmentsProps {
  isLocked: boolean;
  isRefreshing: boolean;
  disabled?: boolean;
  onLock: () => void;
  onUnlock: () => void;
  onRefresh: () => void;
}

const LoadingAnimation = {
  color: 'rgba(0, 0, 0, 0.3)',
  animation: 'spin 2s linear infinite',
  '@keyframes spin': {
    '0%': { transform: 'rotate(360deg)' },
    '100%': { transform: 'rotate(0deg)' },
  },
};

const ButtonAdornments: React.FC<ButtonAdornmentsProps> = ({
  isLocked,
  isRefreshing,
  disabled,
  onLock,
  onUnlock,
  onRefresh,
}) => (
  <InputAdornment position="end">
    <ButtonGroup
      sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
      variant="outlined"
      size="small"
      aria-label="conversion rate buttons"
    >
      <Button
        color="inherit"
        onClick={isLocked ? onUnlock : onLock}
        disabled={isRefreshing || disabled}
      >
        {isLocked ? (
          <LockOutlinedIcon fontSize="small" />
        ) : (
          <LockOpenOutlinedIcon fontSize="small" />
        )}
      </Button>
      <Button
        color="inherit"
        onClick={onRefresh}
        disabled={isRefreshing || disabled || isLocked}
      >
        <LoopOutlinedIcon
          fontSize="small"
          sx={isRefreshing ? LoadingAnimation : {}}
        />
      </Button>
    </ButtonGroup>
  </InputAdornment>
);

interface ConversionRateFieldProps<T extends string = string> {
  name: T;
  label: string;
  isLocked: boolean;
  onLock: () => void;
  onUnlock: () => void;
  onChange?: (newRate: number) => void;
  currencyCode: string | null;
  required?: boolean;
  disabled?: boolean;
}

const ConversionRateField = <T extends string = string>({
  name,
  label,
  isLocked,
  onLock,
  onUnlock,
  onChange,
  currencyCode,
  required = false,
  disabled = false,
}: ConversionRateFieldProps<T>) => {
  const [field, , helpers] = useField<number | null>(name);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  const onRefresh = async () => {
    if (!currencyCode) {
      return;
    }
    setIsRefreshing(true);
    try {
      const response: ExchangeRateDto = await getExchangeRate(currencyCode);
      onChange ? onChange(response.rate) : helpers.setValue(response.rate);
    } catch (error) {
    } finally {
      setIsRefreshing(false);
    }
  };

  return (
    <FormControl fullWidth>
      <TextField
        {...field}
        label={label}
        required={required}
        disabled={isLocked || disabled}
        onBlur={field.onBlur}
        InputProps={{
          inputComponent: ConversionRateFormat as never,
          endAdornment: (
            <ButtonAdornments
              disabled={disabled}
              isLocked={isLocked}
              isRefreshing={isRefreshing}
              onLock={() => {
                onLock?.();
              }}
              onUnlock={() => {
                onUnlock?.();
              }}
              onRefresh={onRefresh}
            />
          ),
          inputProps: {
            onChange: (newRate: unknown) => {
              onChange
                ? onChange(newRate as number)
                : helpers.setValue(newRate as number);
            },
          },
        }}
      />
    </FormControl>
  );
};

export default ConversionRateField;
