import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

export type TokenexDisplayFieldProps<T extends string = string> = {
  name: T;
  token: string;
};

type IframeType = {
  on: (
    event: string,
    callback: (data: Record<string, unknown> & { token?: string }) => void,
  ) => void;
  load: () => void;
};

const TokenexDisplayField = <T extends string = string>({
  name,
  token,
}: TokenexDisplayFieldProps<T>) => {
  const iframeRef = useRef<IframeType>(null);

  const [auth, setAuth] = useState<{ hmac: string; timestamp: number }>();

  const loadAuth = async () => {
    const response = await fetch(`/api/tokenex/hmac-detokenize?token=${token}`);
    const { hmac, timestamp } = await response.json();

    setAuth({ hmac, timestamp });
  };

  useEffect(() => {
    loadAuth();
  }, []);

  useEffect(() => {
    if (auth?.hmac && !iframeRef.current) {
      const iframeConfig = {
        authenticationKey: auth.hmac,
        timestamp: auth.timestamp,
        expiresInSeconds: 60, //60 seconds is the max allowed by tokenex
        origin: process.env.NEXT_PUBLIC_APP_URL || window.location.origin,
        tokenExID: process.env.NEXT_PUBLIC_TOKEN_EX_ID,
        token,
        debug: true,
        styles: {
          base: `            
            font-family: Roboto, Arial,sans-serif;         
            font-size: 15px;               
            line-height: 1.5em;
            color: #000;
            border: none;
            background-color: transparent;`,
        },
      };

      //@ts-ignore-next-line
      iframeRef.current = new TokenEx.DetokenizeIframe(
        `tokenex-display-${name}`,
        iframeConfig,
      ) as IframeType;

      iframeRef.current.load();

      //if the tokenex auth expires, reload the iframe with new hmac and timestamp
      iframeRef.current.on('expired', () => {
        //@ts-ignore-next-line
        iframeRef.current = null;
        loadAuth();
      });
    }
  }, [auth?.hmac]);

  if (!auth?.hmac || !auth?.timestamp) return null;
  return <Box height={25} id={`tokenex-display-${name}`} />;
};

export default TokenexDisplayField;
