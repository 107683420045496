import { useOrganizationRoles } from 'hooks';
import Select, { type SelectProps } from './Select';

type OrganizationRoleSelectorProps<T extends string = string> = Omit<
  SelectProps,
  'options'
>;

const OrganizationRoleSelector = <T extends string = string>(
  props: OrganizationRoleSelectorProps<T>,
) => {
  const { organizationRoles } = useOrganizationRoles();

  return <Select {...props} options={organizationRoles} />;
};

export default OrganizationRoleSelector;
