import { useField, useFormikContext } from 'formik';
import { MuiTelInput, type MuiTelInputProps } from 'mui-tel-input';
import type React from 'react';

interface PhoneFieldProps<T extends string = string> extends MuiTelInputProps {
  name: T;
}

const PhoneField = <T extends string = string>({
  name,
  ...props
}: PhoneFieldProps<T>) => {
  const [field, meta, helpers] = useField(name);
  const { submitCount } = useFormikContext();

  return (
    <MuiTelInput
      defaultCountry="US"
      autoCorrect="on"
      fullWidth
      {...field}
      {...props}
      onChange={(value) => helpers.setValue(value)}
      error={submitCount > 0 && !!meta.error}
      helperText={submitCount > 0 && meta.error}
    />
  );
};

export default PhoneField;
