import { InputAdornment, Stack, type TextFieldProps } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import {
  type InputAttributes,
  NumericFormat,
  type NumericFormatProps,
} from 'react-number-format';
import MoneyFormat from './MoneyFormat';
import TextField from './TextField';

type CustomTextFieldProps<T extends string = string> = {
  name: T;
  required?: boolean;
  labelWhenShrunk?: string;
  negative?: boolean;
  variant?: TextFieldProps['variant'];
  onChange?: (value: number) => void;
  NumericFormatProps?: NumericFormatProps<InputAttributes>;
  symbol?: string;
  homeCurrencyConversion?: number | null;
  currencyCode?: string | null;
} & Omit<TextFieldProps, 'name' | 'inputComponent' | 'onChange'>;

export default function MoneyTextField<T extends string = string>({
  InputProps,
  NumericFormatProps,
  onChange,
  symbol,
  homeCurrencyConversion,
  currencyCode,
  ...props
}: CustomTextFieldProps<T>): JSX.Element {
  const { name } = props;
  const [field, meta, helpers] = useField(name);
  const { submitCount } = useFormikContext();

  const endAdornment = homeCurrencyConversion ? (
    <InputAdornment position="end">
      <Stack direction="row" spacing={1} alignItems="center">
        <NumericFormat
          value={homeCurrencyConversion}
          displayType="text"
          thousandSeparator
          valueIsNumericString
          decimalScale={2}
          fixedDecimalScale
          suffix={currencyCode ? ` ${currencyCode}` : ''}
        />
        {InputProps?.endAdornment}
      </Stack>
    </InputAdornment>
  ) : (
    <InputAdornment position="end">
      <Stack direction="row" spacing={1} alignItems="center">
        {currencyCode}
        {InputProps?.endAdornment}
      </Stack>
    </InputAdornment>
  );

  return (
    <TextField
      {...field}
      {...props}
      InputProps={{
        ...InputProps,
        inputComponent: MoneyFormat as never,
        inputProps: {
          ...(NumericFormatProps ? { ...NumericFormatProps } : {}),
          onChange: (value: unknown) => {
            helpers.setValue(value);
            onChange?.(value as number);
          },
        } as never,
        startAdornment: (
          <InputAdornment position="start">
            {props?.negative && <>&mdash;</>} {symbol || '$'}
          </InputAdornment>
        ),
        endAdornment,
      }}
      error={submitCount > 0 && !!meta.error}
      helperText={submitCount > 0 && meta.error}
    />
  );
}
