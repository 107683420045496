import { type TextFieldProps, useTheme } from '@mui/material';

import TextField from './TextField';

type CustomTextFieldProps<T extends string = string> = {
  name: T;
  labelWhenShrunk?: string;
} & Omit<TextFieldProps, 'name'>;

const NotesField = <T extends string = string>(
  props: CustomTextFieldProps<T>,
) => {
  const theme = useTheme();

  const { sx: _, ...rest } = props;

  return (
    <TextField
      fullWidth
      multiline
      sx={{
        height: '100%',
        display: 'flex',
        backgroundColor: theme.palette.background.light,
        ...props.sx,
      }}
      InputProps={{
        style: {
          height: '100%',
          alignItems: 'flex-start',
          overflowY: 'auto',
        },
      }}
      maxRows={20}
      {...rest}
    />
  );
};

export default NotesField;
