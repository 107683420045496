import {
  Autocomplete,
  Chip,
  type SxProps,
  TextField,
  type Theme,
  createFilterOptions,
} from '@mui/material';
import type { ListItemWithIcon } from 'components/types';
import { TagType } from 'dtos';
import { useField, useFormikContext } from 'formik';
import { useTags } from 'hooks';
import { useEffect, useState } from 'react';
import { getError } from 'utils/client/formik';
import type { FormikMetaWithError } from 'utils/client/types';

const filter = createFilterOptions<ListItemWithIcon>();

type SupplierBadgeMultiselectProps<T extends string = string> = {
  disabled?: boolean;
  helperText?: React.ReactNode;
  idField?: string;
  label: string;
  name: T;
  onChange?: (value: ListItemWithIcon[]) => void;
  required?: boolean;
  sx?: SxProps<Theme> | undefined;
};

const SupplierBadgeMultiselect = <T extends string = string>({
  disabled = false,
  helperText,
  idField = 'id',
  label,
  name,
  onChange,
  required = false,
  sx,
}: SupplierBadgeMultiselectProps<T>) => {
  const [field, meta, helpers] = useField<ListItemWithIcon[]>(name);
  const { submitCount } = useFormikContext();

  const [options, setOptions] = useState<ListItemWithIcon[]>([]);

  const { tags: badges, isLoading } = useTags({
    type: TagType.SUPPLIER_BADGE,
  });

  useEffect(() => {
    if (badges && !isLoading)
      setOptions(
        badges.map(
          ({ id, name, icon }) => ({ id, name, icon }) as ListItemWithIcon,
        ) || [],
      );
  }, [badges, isLoading]);

  const errorOrHelperText =
    (submitCount > 0 && getError(meta as FormikMetaWithError, idField)) ||
    helperText;

  return (
    <Autocomplete
      {...field}
      fullWidth
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      sx={sx}
      options={options}
      disabled={disabled}
      multiple
      onChange={(_event, newValue) => {
        helpers.setValue(newValue as ListItemWithIcon[]);
        if (onChange) {
          onChange(newValue as ListItemWithIcon[]);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        return filtered;
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => <li {...props}>{option.name}</li>}
      renderInput={(params) => (
        <TextField
          {...params}
          helperText={errorOrHelperText}
          InputProps={params.InputProps}
          label={label}
          placeholder="Select badges"
          required={required}
          onFocus={() => {
            helpers.setTouched(true);
          }}
          onBlur={() => {
            helpers.setTouched(false);
          }}
          error={
            submitCount > 0 && !!getError(meta as FormikMetaWithError, idField)
          }
        />
      )}
      renderTags={(values, getTagProps) =>
        values.map((option, index) => (
          // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
          <Chip label={option.name} {...getTagProps({ index })} />
        ))
      }
    />
  );
};

export default SupplierBadgeMultiselect;
