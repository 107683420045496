import type {
  AccountingPaymentMethodListResponseDto,
  AccountingPaymentMethodResponseDto,
} from 'dtos';
import useSWR from 'swr';
import { fetcher } from 'utils/client/swr/jsonFetcher';

type UseSupplierPaymentMethodsResponse = {
  data: AccountingPaymentMethodResponseDto[];
  isLoading: boolean;
  error: Error | undefined;
  refresh: () => void;
};

export default function useSupplierPaymentMethods(): UseSupplierPaymentMethodsResponse {
  const endpoint = 'supplier-payment-methods';
  const {
    data: response,
    isValidating: isLoading,
    error,
    mutate,
  } = useSWR<AccountingPaymentMethodListResponseDto, Error>(endpoint, fetcher);

  return {
    data: response?.data || [],
    isLoading,
    error,
    refresh: () => void mutate(),
  };
}
