import { Avatar, type SxProps, useTheme } from '@mui/material';

import T from './T';

export type GroupAvatarProps = {
  color: string;
  name?: string;
  sx?: SxProps;
};

export default function GroupAvatar({
  color,
  name,
  sx = {},
}: GroupAvatarProps): JSX.Element {
  const theme = useTheme();

  const sxValues = {
    ...sx,
    ...(color
      ? {
          color: `${color}!important`,
          backgroundColor: `${color}33`,
        }
      : { backgroundColor: theme.palette.background.lighter }),
  };

  const getInitials = (name?: string) => {
    if (!name) return '';

    const [firstPart, lastPart] = name.split(' ');

    return `${firstPart?.charAt(0) ?? ''}${lastPart?.charAt(0) ?? ''}`;
  };

  return (
    <Avatar sx={sxValues}>
      <T>{getInitials(name)}</T>
    </Avatar>
  );
}
