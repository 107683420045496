import { Stack, useTheme } from '@mui/material';
import type { ListItem } from 'components/types';
import type { GroupResponseDto } from 'dtos';
import ClientMultiselectGroupAccordion from './ClientMultiselectGroupAccordion';

export type ClientMultiselectGroupsSectionProps = {
  groups: GroupResponseDto[];
  selectedClients: ListItem[];
  setSelectedClients: (clients: ListItem[]) => void;
};

export default function ClientMultiselectGroupsSection({
  groups,
  selectedClients,
  setSelectedClients,
}: ClientMultiselectGroupsSectionProps) {
  const theme = useTheme();
  return (
    <Stack
      mb={1}
      sx={{
        borderBottom: `1px solid ${theme.palette.divider}`,
      }}
    >
      {groups.map((group) => {
        return (
          <ClientMultiselectGroupAccordion
            key={group.id}
            group={group}
            selectedClients={selectedClients}
            setSelectedClients={(clients) => setSelectedClients(clients)}
          />
        );
      })}
    </Stack>
  );
}
