import useSWR from 'swr';

import { fetcher } from '../utils/client/swr/jsonFetcher';

export default function useAdvisorExpenseCategories() {
  const {
    data,
    isValidating: isLoading,
    error,
  } = useSWR<{ data: { id: string; name: string }[] }, Error>(
    'expense-categories',
    fetcher,
  );

  return { data: data?.data, isLoading, error };
}
