import { InputAdornment, type TextFieldProps } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import type { InputAttributes, NumericFormatProps } from 'react-number-format';
import PercentFormat from './PercentFormat';
import TextField from './TextField';

type CustomTextFieldProps<T extends string = string> = {
  name: T;
  required?: boolean;
  labelWhenShrunk?: string;
  negative?: boolean;
  onChange?: (value: number) => void;
  variant?: 'standard' | 'outlined' | 'filled';
  NumericFormatProps?: NumericFormatProps<InputAttributes>;
} & Omit<TextFieldProps, 'name' | 'onChange' | 'inputComponent'>;

export default function PercentTextField<T extends string = string>({
  onChange,
  InputProps,
  NumericFormatProps,
  ...props
}: CustomTextFieldProps<T>): JSX.Element {
  const { name } = props;
  const [field, meta, helpers] = useField(name);
  const { submitCount } = useFormikContext();

  return (
    <TextField
      {...field}
      {...props}
      InputProps={{
        ...InputProps,
        inputComponent: PercentFormat as never,
        inputProps: {
          ...(NumericFormatProps ? { ...NumericFormatProps } : {}),
          onChange: (value: unknown) => {
            helpers.setValue(value);
            onChange?.(value as number);
          },
        } as never,
        startAdornment: (
          <InputAdornment position="start">
            {props?.negative && <>&mdash;</>}%
          </InputAdornment>
        ),
      }}
      error={submitCount > 0 && !!meta.error}
      helperText={submitCount > 0 && meta.error}
    />
  );
}
