import { ComboBox, TextField } from '@customFormik';
import { Stack } from '@mui/material';
import { useFormikContext } from 'formik';
import { useIso3166Countries } from 'hooks/useIso3166Countries';

type AddressBasicProps = {
  namePrefix?: string;
};

type AddressField = {
  country?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
};

type AddressValues = {
  [key: string]: AddressField | AddressField[];
};

const getNestedCountryValue = (
  values: AddressValues,
  path: string | undefined,
): string => {
  if (!path) return (values as AddressField)?.country || '';

  return (
    (
      path.split('.').reduce((obj: unknown, key) => {
        if (!obj || typeof obj !== 'object') return undefined;

        // Handle array notation like addresses[1]
        const match = key.match(/(\w+)\[(\d+)\]/);
        if (match) {
          const [, arrayName, index] = match;
          const value = (obj as Record<string, unknown>)[arrayName];
          return Array.isArray(value) ? value[Number(index)] : undefined;
        }

        return (obj as Record<string, unknown>)[key];
      }, values) as AddressField | undefined
    )?.country || ''
  );
};

export default function AddressBasic({ namePrefix }: AddressBasicProps) {
  const { values } = useFormikContext<AddressValues>();

  const selectedCountry = getNestedCountryValue(values, namePrefix);
  const { countries, regions } = useIso3166Countries(selectedCountry);

  const getFieldName = (field: string) =>
    namePrefix ? `${namePrefix}.${field}` : field;

  return (
    <Stack spacing={2}>
      <ComboBox
        label="Country"
        name={getFieldName('country')}
        options={countries.map((c) => c.countryName)}
        sx={{ width: '100%' }}
      />
      <TextField
        label="Street address or P.O. Box"
        name={getFieldName('address1')}
        required
      />
      <TextField
        label="Apt, suite, unit, building, floor, etc."
        name={getFieldName('address2')}
      />
      <Stack direction="row" spacing={2}>
        <TextField
          label="City"
          name={getFieldName('city')}
          required
          sx={{ width: '100%' }}
        />
        <ComboBox
          label="State / Province / Region"
          name={getFieldName('state')}
          options={regions}
          required
          sx={{ width: '100%' }}
        />
      </Stack>
      <TextField
        label="ZIP / Postal Code"
        name={getFieldName('zip')}
        required
      />
    </Stack>
  );
}
