import AchIcon from '@mui/icons-material/AccountBalance';
import WireIcon from '@mui/icons-material/Cable';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CheckIcon from '@mui/icons-material/Money';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  type SelectProps,
} from '@mui/material';
import { PaymentMethod } from 'dtos';
import { useField, useFormikContext } from 'formik';
import useSupplierPaymentMethods from 'hooks/useSupplierPaymentMethods';
import type { ChangeEvent } from 'react';

type SupplierPaymentPaymentMethodSelectorProps<T extends string = string> = {
  name: T;
  label: string;
  required?: boolean;
  hideCreditCard?: boolean;
  onChange?: (event: ChangeEvent<{ value: unknown }>) => void;
  validateOnChange?: boolean;
  helperText?: string;
} & Omit<SelectProps, 'name'>;

export default function SupplierPaymentPaymentMethodSelector<
  T extends string = string,
>({
  name,
  label,
  required,
  hideCreditCard = false,
  onChange,
  validateOnChange = false,
  helperText: propsHelperText,
  ...rest
}: SupplierPaymentPaymentMethodSelectorProps<T>) {
  const [field, meta] = useField(name);
  const { submitCount } = useFormikContext();

  const { data: methods, isLoading } = useSupplierPaymentMethods();

  const error = validateOnChange
    ? !!meta.error
    : submitCount > 0 && !!meta.error;
  const helperText = validateOnChange
    ? meta.error ?? propsHelperText
    : submitCount > 0
      ? meta.error
      : propsHelperText;

  return (
    <FormControl required={required} fullWidth error={error}>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Select
        {...field}
        {...rest}
        onChange={onChange ? onChange : field.onChange}
        label={label}
        renderValue={(m) => {
          const current = methods.find((x) => x.id === m);
          return (
            <MenuItem
              disableRipple
              disableTouchRipple
              sx={{
                p: 0,
                m: 0,
                '&:hover': { backgroundColor: 'transparent' },
              }}
            >
              {isLoading ? (
                <ListItemText primary={'Loading...'} />
              ) : (
                <>
                  {current && (
                    <ListItemIcon>{icons[current?.type]}</ListItemIcon>
                  )}
                  <ListItemText primary={current?.name || m} />
                </>
              )}
            </MenuItem>
          );
        }}
      >
        {methods.map((m) => (
          <MenuItem key={m.id} value={m.id}>
            <ListItemIcon>{icons[m.type]}</ListItemIcon>
            <ListItemText primary={m.name} />
          </MenuItem>
        ))}
      </Select>
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

const icons = {
  [PaymentMethod.ACH]: <AchIcon fontSize="small" />,
  [PaymentMethod.CHECK]: <CheckIcon fontSize="small" />,
  [PaymentMethod.CREDIT_CARD]: <CreditCardIcon fontSize="small" />,
  [PaymentMethod.WIRE]: <WireIcon fontSize="small" />,
  [PaymentMethod.CASH]: <CheckIcon fontSize="small" />,
  [PaymentMethod.GIFT_CARD]: <CreditCardIcon fontSize="small" />,
};
