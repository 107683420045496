import { InputAdornment, type InputAdornmentProps } from '@mui/material';
import type React from 'react';

type CurrencyAdornmentProps = InputAdornmentProps & {
  symbol?: string;
};

const CurrencyAdornment: React.FC<CurrencyAdornmentProps> = ({
  symbol,
  ...adornmentProps
}) => {
  return symbol ? (
    <InputAdornment {...adornmentProps}>{symbol}</InputAdornment>
  ) : null;
};

export default CurrencyAdornment;
