import { ExtendedFormDialog } from '@customFormik';
import ClientForm from 'components/clients/individual/ClientForm';
import formSchema from 'components/clients/individual/schema';
import type { ClientProfileResponseDto } from 'dtos';
import { useUserAgencies } from 'hooks';
import { createClient } from 'requests/clients';

interface AddClientDialogProps {
  open: boolean;
  onSuccess: (client: ClientProfileResponseDto) => void;
  onClose: () => void;
  entity?: Partial<ClientProfileResponseDto>;
  isCorporateEditable?: boolean;
  showGroupsSection?: boolean;
}

const AddClientDialog = ({
  open,
  onSuccess,
  onClose,
  entity,
  isCorporateEditable = true,
  showGroupsSection = true,
}: AddClientDialogProps) => {
  const { agencies: userAgencies } = useUserAgencies({
    getAll: true,
    sortModel: { sort: 'lastUsed', sortDirection: 'desc' },
  });

  return (
    <ExtendedFormDialog
      width={650}
      title="Add Client"
      entity={entity}
      open={open}
      setOpen={(open) => {
        if (!open) onClose();
      }}
      onSuccess={(client) => onSuccess(client as ClientProfileResponseDto)}
      formSchema={formSchema}
      FormComponent={ClientForm}
      context={{
        userAgencies: userAgencies ?? [],
        isCorporateEditable,
        showGroupsSection,
      }}
      onSave={(c) => createClient(c)}
    />
  );
};

export default AddClientDialog;
