import type { SxProps, Theme } from '@mui/system';
import { useEffect, useState } from 'react';
import { getFeeTypes } from 'requests/feeTypes';
import Select from './Select';

type FeeTypeSelectorProps<T extends string = string> = {
  name: T;
  label: string;
  onChange?: (value: string) => void;
  required?: boolean;
  sx?: SxProps<Theme> | undefined;
  disabled?: boolean;
};
const FeeTypeSelector = <T extends string = string>(
  props: FeeTypeSelectorProps<T>,
) => {
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    [],
  );

  useEffect(() => {
    getFeeTypes()
      .then((response) => {
        const feeTypes = response.data;

        setOptions(
          feeTypes.map((feeType) => ({
            value: feeType.id,
            label: feeType.name,
          })),
        );
      })
      .catch(() => {});
  }, []);

  return <Select {...props} options={options} />;
};

export default FeeTypeSelector;
