import {
  Box,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  Radio,
  type RadioGroupProps,
} from '@mui/material';
import { useField, useFormikContext } from 'formik';

export type CustomRadioGroupOption = {
  label: string | JSX.Element;
  value: string;
};

export type CustomRadioGroupProps<T extends string = string> = {
  name: T;
  label?: string;
  onChange?: (newVal: string) => void;
  disabled?: boolean;
  options: CustomRadioGroupOption[];
  direction?: 'column' | 'row'; // Add direction prop
} & Omit<RadioGroupProps, 'name' | 'onChange'>;

const RadioGroup = <T extends string = string>({
  name,
  label = '',
  disabled = false,
  options,
  direction = 'column', // Set default direction to 'column'
  ...props
}: CustomRadioGroupProps<T>) => {
  const [field, meta, helpers] = useField(name);
  const { submitCount, setFieldValue } = useFormikContext();

  return (
    <Box sx={props.sx}>
      <FormLabel htmlFor={name} id={`radio-group-label-${name}`}>
        {label}
      </FormLabel>

      <MuiRadioGroup
        {...props}
        aria-labelledby={`radio-group-label-${name}`}
        name={name}
        value={field.value}
        onChange={(_, newValue) => {
          props.onChange?.(newValue);
          setFieldValue(name, newValue);
        }}
        onFocus={() => {
          helpers.setTouched(true);
        }}
        onBlur={() => {
          helpers.setTouched(false);
        }}
        sx={{ flexDirection: direction }}
      >
        {options.map((option) => (
          <FormControlLabel
            disabled={disabled}
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </MuiRadioGroup>
      {submitCount > 0 && meta.error && (
        <FormHelperText sx={{ color: 'error.main' }}>
          {meta.error}
        </FormHelperText>
      )}
    </Box>
  );
};

export default RadioGroup;
