import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Select as MUISelect,
  MenuItem,
  type SelectChangeEvent,
  type SxProps,
  type Theme,
  Typography,
} from '@mui/material';
import { currencies, getCurrencyByCode } from 'data/currencies';
import type {
  AdminOrganizationCurrencyMappingResponseDto,
  CurrencyDto,
  UserInfoResponseDto,
} from 'dtos';
import { useField, useFormikContext } from 'formik';
import { useUserInfo } from 'hooks';
import useCurrencyMappings from 'hooks/useCurrencyMappings';
import React, { useMemo } from 'react';
import CurrencyAdornment from '../CurrencyAdornment';

const CurrencyItem = ({ code, name }: CurrencyDto) => (
  <Grid container justifyContent="start">
    <Grid item xs={2} color="rgba(0, 0, 0, 0.6)">
      {code}
    </Grid>
    <Grid item xs={10} zeroMinWidth>
      <Typography component="span" pl={1} noWrap textOverflow="ellipsis">
        {name}
      </Typography>
    </Grid>
  </Grid>
);

type CurrencySelectorProps<T extends string = string> = {
  name: T;
  label: string;
  sx?: SxProps<Theme>;
  useManagedCurrencies?: boolean; // bookings don't limit to retool-managed currencies, client/supplier payments do
  onChange?: (value: string) => void; // Optional onChange prop
  disabled?: boolean;
};

function getManagedCurrencyOptions(
  currencyMappings: AdminOrganizationCurrencyMappingResponseDto[],
) {
  return currencyMappings
    .map(({ currencyCode }) => getCurrencyByCode(currencyCode))
    .filter(Boolean) as CurrencyDto[];
}

function getUnmanagedCurrencyOptions(userInfo?: UserInfoResponseDto) {
  if (!userInfo) return [];
  return Object.values(currencies).sort((a, b) => {
    if (a.code === userInfo.homeCurrency.code) return -1;
    if (b.code === userInfo.homeCurrency.code) return 1;
    return 0;
  });
}

const CurrencySelector = <T extends string = string>({
  name,
  label,
  sx,
  useManagedCurrencies = false,
  onChange,
  disabled = false,
}: CurrencySelectorProps<T>) => {
  const [field, meta, helpers] = useField(name);
  const { submitCount } = useFormikContext();
  const error = submitCount > 0 && !!meta.error;

  const { userInfo } = useUserInfo();

  const { data: currencyMappings } = useCurrencyMappings();

  const currencyOptions = useMemo(() => {
    if (!userInfo) return [];
    return useManagedCurrencies
      ? getManagedCurrencyOptions(currencyMappings ?? [])
      : getUnmanagedCurrencyOptions(userInfo);
  }, [userInfo, currencyMappings, useManagedCurrencies]);

  const currentCurrency = useMemo(
    () =>
      userInfo && field.value ? getCurrencyByCode(field.value) : undefined,
    [userInfo, field.value],
  );

  const handleChange = (event: SelectChangeEvent) => {
    if (onChange) {
      onChange(event.target.value);
    } else {
      helpers.setValue(event.target.value);
    }
  };

  return (
    <FormControl fullWidth error={error} sx={sx} disabled={disabled}>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <MUISelect
        {...field}
        labelId={`${name}-label`}
        label={label}
        notched={!!field.value}
        onChange={handleChange}
        onBlur={field.onBlur}
        startAdornment={
          field.value && (
            <CurrencyAdornment
              symbol={currentCurrency?.symbol}
              position="start"
            />
          )
        }
        renderValue={(value) => {
          if (!value) return <em>None</em>;
          return <>{currentCurrency?.code}</>;
        }}
      >
        {currencyOptions.map((currency) => (
          <MenuItem key={currency.code} value={currency.code}>
            <CurrencyItem {...currency} />
          </MenuItem>
        ))}
      </MUISelect>
      {error && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  );
};

export default CurrencySelector;
