import ClientForm from 'components/clients/individual/ClientForm';
import formSchema from 'components/clients/individual/schema';
import {
  type ClientCreateRequestDto,
  type ClientProfileResponseDto,
  ErrorResponseDto,
} from 'dtos';
import ExtendedFormDialog from '../forms/formik/ExtendedFormDialog';

const AddAdvisorDialog = ({
  entity,
  open,
  setOpen,
  onSave,
}: {
  entity?: ClientProfileResponseDto;
  open: boolean;
  setOpen: (isOpen: boolean) => void | Promise<void>;
  onSave: (
    advisor: ClientCreateRequestDto,
  ) => Promise<ClientProfileResponseDto>;
}) => (
  <ExtendedFormDialog
    title="Add Advisor"
    width={500}
    entity={entity}
    open={open}
    setOpen={setOpen}
    onSave={onSave}
    onSuccess={(_advisor) => {}}
    FormComponent={ClientForm}
    formSchema={formSchema}
  />
);

export default AddAdvisorDialog;
