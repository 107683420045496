import { Divider, FormControl, Stack, useTheme } from '@mui/material';
import AgencySelector from 'components/common/forms/formik/AgencySelector';
import ComboBox from 'components/common/forms/formik/ComboBox';
import CorporateGroupSelector from 'components/common/forms/formik/CorporateGroupSelector';
import PhoneField from 'components/common/forms/formik/PhoneField';
import RadioGroup from 'components/common/forms/formik/RadioGroup';
import Switch from 'components/common/forms/formik/Switch';
import TextField from 'components/common/forms/formik/TextField';
import { SourcedBy } from 'dtos';
import { useField, useFormikContext } from 'formik';
import { useOrganizationRoles } from 'hooks';
import { useState } from 'react';
import { useTypedFormikContext } from 'utils/client/formik';
import ClientFormGroupsSection from './ClientFormGroupsSection';
import {
  type ClientFormValues,
  type ContextValues,
  FieldNames,
} from './schema';

type ClientFormProps = {
  context: ContextValues;
};

export default function ClientForm({ context }: ClientFormProps) {
  const theme = useTheme();
  const [groups, setGroups] = useState<MinimalGroup[]>([]);
  const { setFieldValue } = useFormikContext<ClientFormValues>();
  const { values } = useTypedFormikContext<ClientFormValues>();
  const { isCorporateEditable = true, userAgencies = [] } = context || {};
  const { organizationRoles } = useOrganizationRoles();

  const [{ value: linkToCorporateClient }] = useField(FieldNames.IS_LINKED);

  type T = FieldNames;

  return (
    <Stack spacing={2}>
      {userAgencies.length > 1 && (
        <AgencySelector label="Agency" name={FieldNames.AGENCY} required />
      )}

      <Stack spacing={2}>
        <Stack direction="row" spacing={2}>
          <TextField<T>
            name={FieldNames.FIRST_NAME}
            label="First Name"
            required
          />
          <TextField<T> name={FieldNames.MIDDLE_NAME} label="Middle Name" />
        </Stack>
        <TextField<T> name={FieldNames.LAST_NAME} required label="Last Name" />
      </Stack>

      <Stack spacing={3}>
        <TextField<T> name={FieldNames.EMAIL} type="email" label="Email" />
        <FormControl fullWidth sx={{ mb: 2 }}>
          <PhoneField name={FieldNames.PHONE} label="Phone" />
        </FormControl>
      </Stack>
      {context.showGroupsSection && (
        <ClientFormGroupsSection
          groups={groups}
          setGroups={(updatedGroups) => {
            setGroups(updatedGroups);
            setFieldValue(
              FieldNames.GROUPS,
              updatedGroups.map((g) => ({
                id: g.id || undefined,
                name: g.id ? undefined : g.name,
              })),
            );
          }}
          client={{
            firstName: values[FieldNames.FIRST_NAME],
            middleName: values[FieldNames.FIRST_NAME],
            lastName: values[FieldNames.LAST_NAME],
          }}
        />
      )}
      <Divider sx={{ mt: `${theme.spacing(3)}!important` }} />
      <Switch
        label="Link to Corporate Client"
        name={FieldNames.IS_LINKED}
        disabled={!isCorporateEditable}
      />
      {linkToCorporateClient && (
        <Stack spacing={2} mt={3}>
          <CorporateGroupSelector
            name={FieldNames.CORPORATE_GROUP}
            label={'Corporate Client'}
            required
            disabled={!isCorporateEditable}
          />
          <Stack direction="row" spacing={2}>
            <TextField<T> name={FieldNames.TITLE} label="Title" />
            <ComboBox
              name={FieldNames.ROLE}
              label="Role"
              options={organizationRoles.map((r) => r.value)}
              fullWidth
            />
          </Stack>
          <TextField<T> name={FieldNames.DEPARTMENT} label="Department" />
        </Stack>
      )}

      <FormControl sx={{ mt: 0 }}>
        <RadioGroup
          sx={{ px: 1 }}
          name={FieldNames.SOURCED_BY}
          options={[
            { label: 'Advisor', value: SourcedBy.ADVISOR },
            { label: 'Agency', value: SourcedBy.AGENCY },
          ]}
        />
      </FormControl>
    </Stack>
  );
}
