import 'react-virtualized/styles.css';

import React, { type FC, type ReactElement } from 'react';
//todo: look at replacing react-virtualized with react-window (former seems not to be maintained anymore)
import {
  type AutoSizerProps,
  type ListProps,
  AutoSizer as _AutoSizer,
  List as _List,
} from 'react-virtualized';

const List = _List as unknown as FC<ListProps>;
const AutoSizer = _AutoSizer as unknown as FC<AutoSizerProps>;

const VirtualizedListbox = React.forwardRef(function ListboxComponent(
  props: { children: ReactElement[] },
  ref: React.LegacyRef<HTMLDivElement> | undefined,
) {
  const { children, ...other } = props;
  const itemCount = Array.isArray(children) ? children.length : 0;
  const itemSize = 36;

  return (
    <div ref={ref}>
      <div {...other} style={{ width: '100%', height: 250 }}>
        <AutoSizer disableWidth>
          {({ height }) => (
            <List
              height={height}
              rowHeight={itemSize}
              width={1}
              containerStyle={{
                width: '100%',
                minWidth: '100%',
              }}
              style={{ width: '100%' }}
              overscanCount={5}
              rowCount={itemCount}
              rowRenderer={(props: {
                index: number;
                style: React.CSSProperties;
              }) => {
                return children
                  ? React.cloneElement(children[props.index], {
                      style: props.style,
                    })
                  : null;
              }}
            />
          )}
        </AutoSizer>
      </div>
    </div>
  );
});

export default VirtualizedListbox;
