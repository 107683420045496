import { FormControl, FormHelperText } from '@mui/material';
import MuiToggleButtonGroup, {
  type ToggleButtonGroupProps as MuiToggleButtonGroupProps,
} from '@mui/material/ToggleButtonGroup';
import { useField, useFormikContext } from 'formik';
import React from 'react';

interface ToggleButtonGroupProps<T extends string = string>
  extends MuiToggleButtonGroupProps {
  name: T;
}

export default function ToggleButtonGroup<T extends string>({
  children,
  ...props
}: ToggleButtonGroupProps<T>) {
  const { name } = props;
  const [field, meta, helpers] = useField(name);
  const { submitCount } = useFormikContext();

  return (
    <FormControl>
      <MuiToggleButtonGroup
        {...field}
        {...props}
        exclusive
        value={field.value}
        onChange={(_, value) => {
          helpers.setValue(value);
        }}
      >
        {children}
      </MuiToggleButtonGroup>
      {!!meta.error && submitCount > 0 && (
        <FormHelperText error={!!meta.error && submitCount > 0}>
          {meta.error}
        </FormHelperText>
      )}
    </FormControl>
  );
}
