import useSWR from 'swr';

import type { AdminOrganizationCurrencyMappingListResponseDto } from 'dtos';
import { fetcher } from '../utils/client/swr/jsonFetcher';

export default function useCurrencyMappings() {
  const {
    data,
    isValidating: isLoading,
    error,
  } = useSWR<AdminOrganizationCurrencyMappingListResponseDto, Error>(
    'currency-mappings',
    fetcher,
  );

  return { data: data?.data, isLoading, error };
}
