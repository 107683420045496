import type { AlertColor } from '@mui/material';
import type { FieldMetaProps, FormikValues } from 'formik';
import * as yup from 'yup';
import type { ObjectShape } from 'yup';

export type SchemaOf<T> = {
  [K in keyof T]: yup.AnySchema;
};

export function createYupSchema<T extends ObjectShape>(
  schemaObj: SchemaOf<T>,
): yup.ObjectSchema<T> {
  return yup.object().shape(schemaObj) as unknown as yup.ObjectSchema<T>;
}

export type RemoveIndex<T> = {
  [K in keyof T as string extends K
    ? never
    : number extends K
      ? never
      : symbol extends K
        ? never
        : K]: T[K];
};

export type UseFormikHelpers<T extends FormikValues> = {
  values: { [K in keyof RemoveIndex<T>]: T[K] };
  setFieldValue: <K extends keyof RemoveIndex<T>>(
    field: K,
    value: T[K],
    shouldValidate?: boolean,
  ) => void;
  setValues: (
    values: { [K in keyof RemoveIndex<T>]: T[K] },
    shouldValidate?: boolean,
  ) => void;
  submitCount: number;
};

/**
 * @deprecated use ExtendedFormSchema instead
 */
export type FormSchema<
  CombinedInputDtoAndCreateOrUpdateType,
  V extends FormikValues,
  Schema extends yup.ObjectSchema<ObjectShape>,
  // eslint-disable-next-line @typescript-eslint/ban-types
  Context extends object = {},
> = ExtendedFormSchema<
  CombinedInputDtoAndCreateOrUpdateType,
  CombinedInputDtoAndCreateOrUpdateType,
  V,
  Schema,
  Context
>;

export type ExtendedFormSchema<
  InputDtoType,
  CreateOrUpdateDtoType,
  ValuesType extends FormikValues,
  Schema extends
    | yup.ObjectSchema<ObjectShape>
    | ((context: Context) => yup.ObjectSchema<ObjectShape>),
  Context extends object = {},
> = {
  schema: Schema;
  getDefaultValues:
    | ((entity: InputDtoType | undefined) => ValuesType)
    | ((entity: InputDtoType | undefined, context: Context) => ValuesType);
  getEntityFromFormValues: (
    values: ValuesType,
    context?: Context,
  ) => CreateOrUpdateDtoType;
};

export type Toast = {
  show: (
    message: string,
    options: {
      severity: AlertColor;
    },
  ) => void;
  info(message: string): void;
  success(message: string): void;
  warning(message: string): void;
  error(message: string): void;
};

export type FormikObjectError = {
  id?: string;
  name?: string;
  [key: string]: string | undefined;
};

export type FormikMetaWithError = FieldMetaProps<unknown> & {
  error: FormikObjectError | string;
};
