import AchIcon from '@mui/icons-material/AccountBalance';
import WireIcon from '@mui/icons-material/Cable';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CheckIcon from '@mui/icons-material/Money';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  type SelectProps,
} from '@mui/material';
import { type AccountingPaymentMethodResponseDto, PaymentMethod } from 'dtos';
import { useField, useFormikContext } from 'formik';
import type * as React from 'react';

type PaymentMethodSelectorProps<T extends string = string> = {
  label: string;
  required?: boolean;
  icon?: React.ReactNode;
  orgBillPaymentMethods: AccountingPaymentMethodResponseDto[];
  name: T;
} & Omit<SelectProps, 'name'>;

export default function BillPaymentMethodSelector<T extends string = string>({
  label,
  required,
  orgBillPaymentMethods,
  name,
  ...rest
}: PaymentMethodSelectorProps<T>) {
  const [field, meta] = useField(name);
  const { submitCount } = useFormikContext();

  return (
    <FormControl
      required={required}
      fullWidth
      error={submitCount > 0 && !!meta.error}
    >
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Select
        {...rest}
        {...field}
        label={label}
        renderValue={(selectedMethodId) => {
          const current = orgBillPaymentMethods.find(
            (method) => method.id === selectedMethodId,
          );
          return (
            <MenuItem
              disableRipple
              disableTouchRipple
              sx={{
                p: 0,
                m: 0,
                '&:hover': { backgroundColor: 'transparent' },
              }}
            >
              {current && icons[current.type] && (
                <ListItemIcon>{icons[current.type]}</ListItemIcon>
              )}
              <ListItemText primary={current?.name || selectedMethodId} />
            </MenuItem>
          );
        }}
      >
        {orgBillPaymentMethods.map((method) => (
          <MenuItem key={method.id} value={method.id}>
            <ListItemIcon>{icons[method.type]}</ListItemIcon>
            <ListItemText primary={method.name} />
          </MenuItem>
        ))}
      </Select>
      {submitCount > 0 && meta.error && (
        <FormHelperText>{meta.error}</FormHelperText>
      )}
    </FormControl>
  );
}

const icons = {
  [PaymentMethod.ACH]: <AchIcon fontSize="small" />,
  [PaymentMethod.CHECK]: <CheckIcon fontSize="small" />,
  [PaymentMethod.CREDIT_CARD]: <CreditCardIcon fontSize="small" />,
  [PaymentMethod.WIRE]: <WireIcon fontSize="small" />,
  [PaymentMethod.CASH]: <CheckIcon fontSize="small" />,
  [PaymentMethod.GIFT_CARD]: <CreditCardIcon fontSize="small" />,
};
