import {
  Autocomplete,
  Chip,
  type SxProps,
  TextField,
  type Theme,
  createFilterOptions,
} from '@mui/material';
import type { CreatableListItem } from 'components/types';
import type { TagType } from 'dtos';
import { useField, useFormikContext } from 'formik';
import { useTags } from 'hooks';
import { useEffect, useState } from 'react';
import { getError } from 'utils/client/formik';
import type { FormikMetaWithError } from 'utils/client/types';

const filter = createFilterOptions<string | CreatableListItem>();

type TagMultiselectProps<T extends string = string> = {
  disabled?: boolean;
  helperText?: React.ReactNode;
  idField?: string;
  label: string;
  name: T;
  onChange?: (value: Array<string | CreatableListItem>) => void;
  required?: boolean;
  sx?: SxProps<Theme> | undefined;
  type: TagType.TRIP | TagType.SUPPLIER;
  allowAdd?: boolean;
};

const TagMultiselect = <T extends string = string>({
  disabled = false,
  helperText,
  idField = 'id',
  label,
  name,
  onChange,
  required = false,
  sx,
  type,
  allowAdd = false,
}: TagMultiselectProps<T>) => {
  const [field, meta, helpers] = useField<string[]>(name);
  const { submitCount } = useFormikContext();

  const [options, setOptions] = useState<
    (string | (CreatableListItem & { inputValue?: string }))[]
  >([]);

  const { tags, isLoading } = useTags({ type });

  useEffect(() => {
    if (!isLoading) setOptions(tags?.map((tag) => tag.name) || []);
  }, [isLoading, tags]);

  const errorOrHelperText =
    (submitCount > 0 && getError(meta as FormikMetaWithError, idField)) ||
    helperText;

  return (
    <Autocomplete
      {...field}
      fullWidth
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      sx={sx}
      options={options}
      freeSolo={allowAdd}
      disabled={disabled}
      multiple
      onChange={(_event, newValue) => {
        const tags = newValue as Array<string | CreatableListItem>;

        const lastTag: CreatableListItem | string = tags[tags.length - 1];

        if (onChange) {
          onChange(tags || null);
        } else {
          if (tags.length > 0) {
            const tagName =
              (lastTag as CreatableListItem)?.inputValue ||
              (lastTag as unknown as string);

            tags[tags.length - 1] = tagName;
          }

          helpers.setValue((newValue as string[]) || null);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;

        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option);
        if (allowAdd && inputValue !== '' && !isExisting) {
          filtered.push({
            id: '',
            inputValue,
            name: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if ((option as CreatableListItem).inputValue) {
          return (option as CreatableListItem).inputValue;
        }
        // Regular option
        return option.name || '';
      }}
      renderOption={(props, option) => (
        <li {...props}>
          {(option as CreatableListItem)?.name || (option as string)}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          helperText={errorOrHelperText}
          InputProps={params.InputProps}
          label={label}
          placeholder="Type and press enter"
          required={required}
          onFocus={() => {
            helpers.setTouched(true);
          }}
          onBlur={() => {
            helpers.setTouched(false);
          }}
          error={
            submitCount > 0 && !!getError(meta as FormikMetaWithError, idField)
          }
        />
      )}
      renderTags={(values, getTagProps) =>
        values.map((option, index) => (
          // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
          <Chip
            label={typeof option === 'string' ? option : option.name}
            {...getTagProps({ index })}
          />
        ))
      }
    />
  );
};

export default TagMultiselect;
