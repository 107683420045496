import {
  Autocomplete,
  Stack,
  type SxProps,
  TextField,
  type Theme,
} from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import T from 'components/common/T';
import AddAdvisorDialog from 'components/common/advisorSelector/AddAdvisorDialog';
import type { CreatableListItem, ListItem } from 'components/types';
import type { ClientProfileResponseDto } from 'dtos';
import { type FieldMetaProps, useField, useFormikContext } from 'formik';
import { useAdvisors } from 'hooks';
import { queryTypes, useQueryStates } from 'next-usequerystate';
import { useEffect, useState } from 'react';
import { createClient } from 'requests/clients';
import { getError } from 'utils/client/formik';

type Advisor = {
  agencyUserId?: string;
  agencyName?: string;
};

type AdvisorListItem = ListItem & Advisor;
type CreatableAdvisorListItem = CreatableListItem & Advisor;

const filter = createFilterOptions<
  AdvisorListItem | CreatableAdvisorListItem
>();

type AdvisorSelectorProps<T extends string = string> = {
  name: T;
  label: string;
  idField?: string;
  required?: boolean;
  onChange?: (value: AdvisorListItem | CreatableAdvisorListItem | null) => void;
  disabled?: boolean;
  sx?: SxProps<Theme> | undefined;
  includeAgencyName?: boolean;
  excludeAgencyUserIds?: string[];
  disableWhenEmpty?: boolean;
};

type FieldType = {
  id: string;
  name: string;
};

type FormikMetaWithError = FieldMetaProps<unknown> & {
  error: {
    id?: string;
    name?: string;
    [key: string]: string | undefined;
  };
};

const AdvisorSelector = <T extends string = string>({
  name,
  label,
  idField = 'id',
  onChange,
  sx,
  disabled = false,
  required = false,
  includeAgencyName = false,
  excludeAgencyUserIds = [],
  disableWhenEmpty = false,
}: AdvisorSelectorProps<T>) => {
  const allowAdd = true;

  const { data: advisors, isLoading } = useAdvisors({
    uniqueAdvisorsOnly: !includeAgencyName,
  });
  const [field, meta, helpers] = useField<FieldType>(name);
  const { submitCount } = useFormikContext();

  const [options, setOptions] = useState<
    (AdvisorListItem | (CreatableAdvisorListItem & { inputValue?: string }))[]
  >([]);

  const [addStatus, setAddStatus] = useQueryStates({
    addingAdvisor: queryTypes.boolean.withDefault(false),
    advisorName: queryTypes.string,
    fieldId: queryTypes.string,
  });

  useEffect(() => {
    if (isLoading) return;

    setOptions(
      advisors.map((advisor) => ({
        id: advisor.id,
        agencyUserId: advisor.agencyUserId,
        name: advisor.name,
        agencyName: advisor.agencyName,
      })),
    );
  }, [isLoading]);

  const isDialogOpen = addStatus.addingAdvisor;

  return (
    <>
      <Autocomplete
        {...field}
        fullWidth
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        sx={sx}
        options={options}
        freeSolo={allowAdd}
        disabled={disabled || (disableWhenEmpty && !options.length)}
        forcePopupIcon
        onChange={(_event, selectedAdvisor) => {
          const advisor = selectedAdvisor as CreatableAdvisorListItem;

          if (!advisor?.inputValue)
            onChange?.(
              advisor || {
                id: '',
                name: '',
              },
            );

          if (!onChange)
            helpers.setValue((selectedAdvisor as FieldType) || null);
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          return filtered.filter(
            (option) =>
              !option.agencyUserId ||
              !excludeAgencyUserIds.includes(option.agencyUserId),
          );
        }}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if ((option as CreatableAdvisorListItem).inputValue) {
            return (option as CreatableAdvisorListItem).inputValue;
          }
          // Regular option
          return option.name as string;
        }}
        renderOption={(props, option) => (
          <li {...props} key={`${option.id}-${option.agencyName || ''}`}>
            {includeAgencyName ? (
              <Stack>
                <T variant="body1">{option.name}</T>
                <T variant="body2" color="text.secondary">
                  {option.agencyName}
                </T>
              </Stack>
            ) : (
              option.name
            )}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            required={required}
            InputProps={params.InputProps}
            error={
              submitCount > 0 &&
              !!getError(meta as FormikMetaWithError, idField)
            }
            helperText={
              submitCount > 0 && getError(meta as FormikMetaWithError, idField)
            }
            onFocus={() => {
              helpers.setTouched(true);
            }}
            onBlur={() => {
              helpers.setTouched(false);
            }}
          />
        )}
      />
      {isDialogOpen && (
        <AddAdvisorDialog
          open={isDialogOpen}
          setOpen={(open) =>
            void setAddStatus({
              addingAdvisor: open || null,
              advisorName: null,
            })
          }
          onSave={async (data) => createClient(data)}
          entity={
            {
              //everything until the first space
              firstName: addStatus.advisorName?.split(' ')[0] ?? '',
              //everything after the first space
              lastName:
                addStatus.advisorName?.split(' ').slice(1).join(' ') ?? '',
            } as ClientProfileResponseDto
          }
        />
      )}
    </>
  );
};

export default AdvisorSelector;
