import { type GroupResponseDto, GroupType } from 'dtos';
import useSWR from 'swr';
import { getUrlSearchParams } from 'utils/client/fetch';
import type { PaginatedData } from '../types/paginatedData';
import { fetcher } from '../utils/client/swr/jsonFetcher';

type UseClientGroupsProps = {
  clientId?: string;
  includeAllMembers?: boolean;
};

const useClientGroups = ({
  clientId,
  includeAllMembers,
}: UseClientGroupsProps) => {
  const queryParams = getUrlSearchParams({
    clientId,
    type: GroupType.LEISURE,
    page: 0,
    pageSize: 100000,
    ...(includeAllMembers && { includeAllMembers }),
  });

  const swfKey = `groups?${queryParams.toString()}`;

  const {
    data: results,
    isValidating: isLoading,
    error,
    mutate: refresh,
  } = useSWR<PaginatedData<GroupResponseDto>, Error>(
    swfKey,
    clientId ? fetcher : null,
  );

  const { data, meta } = (results as PaginatedData<GroupResponseDto>) || {};

  return { data, meta, isLoading, error, refresh };
};

export default useClientGroups;
