import type { SxProps, Theme } from '@mui/material';
import type { CreatableListItem, ListItem } from 'components/types';
import type {
  ClientGroupResponseDto,
  ClientResponseDto,
  ClientWithGroupsResponseDto,
  GroupResponseDto,
} from 'dtos';

export type ClientMultiselectProps<T extends string = string> = {
  allowAdd?: boolean;
  disabled?: boolean;
  helperText?: React.ReactNode;
  //field id is useful for repeated fields, so we can have multiple instances of the same field
  //ex: if name = client[someIndex], we need to know the name of the field in the schema for proper error messages
  fieldId?: string;
  label: string;
  name: T;
  onAddSuccess: (newClient: ClientResponseDto) => void;
  onChange?: (value: Array<ListItem | CreatableListItem>) => void;
  required?: boolean;
  showGroups?: boolean;
  sx?: SxProps<Theme> | undefined;
  displayEmailAndPhone?: boolean;
  clientIdForGroupsSection?: string;
};

export type ExtendedClientListItemInfo = {
  groups?: string;
  email?: string;
  phone?: string;
};

export type ClientListItem = ListItem & ExtendedClientListItemInfo;

export type ClientCreatableListItem = CreatableListItem &
  ExtendedClientListItemInfo;

export type AnyClientListItem = ClientListItem | ClientCreatableListItem;

export type ClientGroupsSection = {
  type: 'groups-section';
  groups: GroupResponseDto[];
};

export const mapClientToClientListItem = (
  client: ClientWithGroupsResponseDto,
): ClientListItem => {
  const groupsString = client.groups
    .filter((group) => !!group.group?.name)
    .map((group) => group.group.name)
    .join(', ');

  return {
    id: client.id,
    name: client.name,
    email: client.email,
    phone: client.phone,
    groups: groupsString,
  };
};
