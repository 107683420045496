import CheckIcon from '@mui/icons-material/Check';
import { Button, Stack, type SxProps } from '@mui/material';
import moment from 'moment-timezone';

const DEFAULT_OPTIONS = [
  { value: 30, label: '30 Days' },
  { value: 45, label: '45 Days' },
  { value: 60, label: '60 Days' },
];

type DueDateButtonsProps = {
  disabled?: boolean;
  value: Date | string | null;
  referenceDate: Date;
  onChange: (value: number) => void;
  options?: typeof DEFAULT_OPTIONS;
  sx?: SxProps;
};

export default function DueDateButtons({
  disabled = false,
  value,
  referenceDate,
  onChange,
  options = DEFAULT_OPTIONS,
  sx = {},
}: DueDateButtonsProps) {
  const getDaysInFuture = (date: Date | string | null) =>
    moment(date)
      .startOf('day')
      .diff(moment(referenceDate).startOf('day'), 'days');

  return (
    <Stack direction="row" justifyContent="space-between" sx={sx} spacing={1}>
      {options.map((option) => (
        <Button
          fullWidth
          key={option.value}
          variant="outlined"
          onClick={() => onChange(option.value)}
          disabled={disabled}
        >
          {getDaysInFuture(value) === option.value && (
            <CheckIcon sx={{ position: 'absolute', left: 4 }} />
          )}
          {option.label}
        </Button>
      ))}
    </Stack>
  );
}
