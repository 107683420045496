import type Decimal from 'decimal.js';
import moment from 'moment-timezone';

export const fullNiceDate = (value?: string | Date): string =>
  value ? moment(value).format('D MMMM YYYY') : '';

export const getPercentageToNearestTenth = (
  numerator: Decimal,
  denominator: Decimal,
) =>
  denominator.isZero()
    ? undefined
    : numerator
        .dividedBy(denominator)
        .times(1000) // times 100 to get whole number, * 10, round, / 10 to get nearest 0.1%
        .round()
        .dividedBy(10)
        .toDecimalPlaces(2)
        .toNumber();

export function getEmailPhoneString(
  email: string | undefined,
  phone: string | undefined,
) {
  if (!email && !phone) return '';
  if (!phone) return email;
  if (!email) return phone;
  return `${email} | ${phone}`;
}
