import { TextField as MuiTextField, type TextFieldProps } from '@mui/material';
import { useField, useFormikContext } from 'formik';

type CustomTextFieldProps<T extends string = string> = {
  name: T;
  labelWhenShrunk?: string;
  validateOnChange?: boolean;
} & Omit<TextFieldProps, 'name'>;

const TextField = <T extends string = string>(
  props: CustomTextFieldProps<T>,
) => {
  const { name, label, labelWhenShrunk, InputLabelProps } = props;
  const [field, meta, helpers] = useField(name);
  const { submitCount } = useFormikContext();

  const shrinkLabel =
    InputLabelProps?.shrink ||
    meta.touched ||
    (field.value !== '' && field.value !== null);

  const useShrunkenLabel =
    (InputLabelProps === undefined && (meta.touched || !!field.value)) ||
    InputLabelProps?.shrink;

  const error = props.validateOnChange
    ? !!meta.error
    : submitCount > 0 && !!meta.error;
  const helperText = props.validateOnChange
    ? meta.error ?? props.helperText
    : submitCount > 0
      ? meta.error
      : props.helperText;

  return (
    <MuiTextField
      fullWidth
      {...field}
      {...props}
      label={useShrunkenLabel ? labelWhenShrunk || label : label}
      onFocus={() => {
        helpers.setTouched(true);
      }}
      onBlur={() => {
        helpers.setTouched(false);
      }}
      InputProps={{
        ...props.InputProps,
        startAdornment: shrinkLabel ? props.InputProps?.startAdornment : null,
        ...(props.InputProps?.inputComponent
          ? {
              onChange: (value: unknown) => {
                helpers.setValue(value);
              },
            }
          : {}),
      }}
      InputLabelProps={{
        shrink: shrinkLabel,
        ...props.InputLabelProps,
      }}
      error={error}
      helperText={helperText}
    />
  );
};

export default TextField;
