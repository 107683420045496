import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { SupplierResponseDto } from 'dtos';
import { Formik } from 'formik';
import { enqueueSnackbar as toast } from 'notistack';
import { createSupplier, updateSupplier } from 'requests/suppliers';

import { useUserInfo } from 'hooks';
import SupplierForm from './SupplierForm';
import { getDefaultValues, getSupplierFromFormValues, schema } from './schema';

type SupplierDialogProps = {
  tripId?: string;
  mode: 'add' | 'edit';
  open: boolean;
  onClose: () => void;
  supplierId?: string;
  supplier?: Partial<SupplierResponseDto>;
  onSuccess: (
    mode: 'add' | 'edit',
    supplier: SupplierResponseDto,
  ) => Promise<void>;
};

export default function SupplierDialog({
  open,
  onClose,
  mode,
  supplierId,
  supplier,
  onSuccess = () => Promise.resolve(),
}: SupplierDialogProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { userInfo } = useUserInfo();

  return (
    <Formik
      initialValues={getDefaultValues(supplier)}
      enableReinitialize
      validationSchema={schema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          const s = getSupplierFromFormValues(values);

          const result =
            mode === 'add'
              ? await createSupplier(s)
              : await updateSupplier(s, supplierId as string);

          toast(
            `Supplier ${mode === 'add' ? 'created' : 'updated'} successfully`,
          );
          await onSuccess(mode, result);
          onClose();
          window.setTimeout(() => resetForm(), 1000);
        } catch (error) {
          console.error(error);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {(formik) => (
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={onClose}
          aria-labelledby="supplier-dialog"
        >
          <DialogTitle id="supplier-dialog">
            {mode === 'add' ? 'New Supplier' : 'Edit Supplier'}
          </DialogTitle>
          <DialogContent
            sx={(theme) => ({
              pt: `${theme.spacing(1)}!important`,
              width: '450px',
            })}
          >
            <SupplierForm isCustom={supplier?.isCustom} mode={mode} />
          </DialogContent>
          <DialogActions>
            <Button disabled={formik.isSubmitting} onClick={onClose}>
              Cancel
            </Button>
            <Button
              disabled={
                formik.isSubmitting || (!userInfo?.isOrgUser && mode === 'edit')
              }
              variant="contained"
              onClick={() => formik.handleSubmit()}
            >
              {mode === 'add' ? 'Create' : 'Update'} Supplier
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}
