import {
  type ExchangeRateDto,
  ExchangeRateRequestDto,
} from 'dtos/exchange-rates';
import ky from 'utils/client/ky';

export const getExchangeRate = async (
  currency: string,
): Promise<ExchangeRateDto> => {
  const queryParams = new URLSearchParams(
    ExchangeRateRequestDto.parse({
      source: currency,
    } as ExchangeRateRequestDto),
  );

  const url = `/api/exchange-rates?${queryParams}`;

  return ky.get(url).json<ExchangeRateDto>();
};
