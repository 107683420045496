import {
  InputAdornment,
  type SxProps,
  type Theme,
  debounce,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import SupplierDialog from 'components/suppliers/SupplierDialog';

import type { CreatableListItem, ListItem } from 'components/types';
import { type SupplierResponseDto, SupplierType } from 'dtos';
import { useField, useFormikContext } from 'formik';
import { useState } from 'react';

import { getSuppliers } from 'requests/suppliers';
import { getSupplierIconByType } from 'utils/client/suppliers';

type SupplierSelectorProps<T extends string = string> = {
  name: T;
  label: string;
  required?: boolean;
  allowAdd: boolean;
  onAddSuccess?: (newSupplier: SupplierResponseDto) => void;
  onChange?: (
    value: (ListItem & { type: SupplierType }) | CreatableListItem | null,
  ) => void;
  sx?: SxProps<Theme> | undefined;
  isParent?: boolean;
  isRetired?: boolean;
  disabled?: boolean;
  validateOnChange?: boolean;
};

type FieldType = {
  id: string;
  name: string;
  type: SupplierType;
};

export default function SupplierSelector<T extends string = string>({
  name,
  label,
  required,
  allowAdd,
  onAddSuccess,
  onChange,
  sx,
  isParent,
  isRetired,
  disabled = false,
  validateOnChange = false,
}: SupplierSelectorProps<T>) {
  const [field, meta, helpers] = useField<FieldType>(name);
  const { submitCount } = useFormikContext();

  const [options, setOptions] = useState<
    (ListItem | (CreatableListItem & { inputValue?: string }))[]
  >([]);

  const [addStatus, setAddStatus] = useState({
    addingSupplier: false,
    supplierName: '',
  });

  const debouncedGetSuppliers = debounce(async (query: string) => {
    const newOptions = await getSuppliers({
      query: query || '',
      pageSize: 500,
      isParent,
      isRetired,
    });

    setOptions(
      newOptions.map((opt: SupplierResponseDto) => ({
        id: opt.id,
        name: opt.name,
        type: opt.type,
      })),
    );
  }, 200);

  const isDialogOpen = addStatus.addingSupplier;
  const error = validateOnChange
    ? !!meta.error
    : submitCount > 0 && !!meta.error;
  const helperText = validateOnChange
    ? meta.error
    : submitCount > 0 && meta.error;

  return (
    <>
      <Autocomplete
        {...field}
        fullWidth
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        sx={sx}
        disabled={disabled}
        options={options}
        freeSolo={allowAdd}
        onInputChange={async (_event, newInputValue) =>
          await debouncedGetSuppliers(newInputValue)
        }
        onChange={(_event, newSupplier) => {
          const supplier = newSupplier as CreatableListItem;

          if (supplier?.inputValue) {
            void setAddStatus({
              addingSupplier: true,
              supplierName: supplier.inputValue,
            });
          } else {
            onChange?.(supplier);
          }

          helpers.setValue(newSupplier as FieldType);
        }}
        filterOptions={(options, params) => {
          const { inputValue } = params;

          // Suggest the creation of a new value
          const isExisting = options.some(
            (option) => inputValue === option.name,
          );

          const newOptions = [...options];

          if (inputValue !== '' && !isExisting) {
            newOptions.push({
              id: '',
              inputValue,
              name: `Add "${inputValue}"`,
            });
          }

          return newOptions;
        }}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if ((option as CreatableListItem).inputValue) {
            return (option as CreatableListItem).inputValue;
          }
          // Regular option
          return option.name as string;
        }}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment:
                (field.value?.type && (
                  <InputAdornment position="start">
                    {getSupplierIconByType(field.value.type || null)}
                  </InputAdornment>
                )) ||
                undefined,
            }}
            onFocus={() => {
              helpers.setTouched(true);
            }}
            onBlur={() => {
              helpers.setTouched(false);
            }}
            label={label}
            error={error}
            helperText={helperText}
            required={required}
          />
        )}
      />
      {isDialogOpen && (
        <SupplierDialog
          mode="add"
          supplier={{
            name: addStatus.supplierName as string,
            type: SupplierType.HOTEL,
          }}
          open={isDialogOpen}
          onClose={() =>
            setAddStatus({
              addingSupplier: false,
              supplierName: '',
            })
          }
          onSuccess={async (_mode, supplier: SupplierResponseDto) => {
            if (onAddSuccess) {
              void onAddSuccess(supplier);
            } else {
              helpers.setValue({
                id: supplier.id,
                name: supplier.name,
                type: supplier.type,
              });
            }
            void setAddStatus({ addingSupplier: false, supplierName: '' });
          }}
        />
      )}
    </>
  );
}
