import { InputAdornment } from '@mui/material';
import { SupplierType, supplierTypeEnumToString } from 'dtos';
import { useField } from 'formik';
import React from 'react';
import { getSupplierIconByType } from 'utils/client/suppliers';
import Select, { type SelectProps } from './Select';

type SupplierTypeSelectorProps<T extends string = string> = {
  exclude?: SupplierType[];
} & Omit<SelectProps, 'options'>;

export default function SupplierTypeSelector<T extends string>({
  name,
  exclude = [SupplierType.ANCILLARIES],
  ...props
}: SupplierTypeSelectorProps<T>) {
  const supplierTypes = Object.values(SupplierType).filter(
    (st) => !exclude?.includes(st),
  );

  const [field] = useField(name);

  return (
    <Select
      name={name}
      {...props}
      startAdornment={
        field.value && (
          <InputAdornment position="start">
            {getSupplierIconByType(field.value as SupplierType)}
          </InputAdornment>
        )
      }
      options={supplierTypes.map((st) => ({
        label: supplierTypeEnumToString(st),
        value: st,
      }))}
    />
  );
}
