import { Stack, type SxProps } from '@mui/material';
import T from 'components/common/T';
import type React from 'react';

type FormSectionProps = {
  title: string;
  children: React.ReactNode;
  includeBottomGap?: boolean;
  sx?: SxProps;
};

export default function FormSection({
  title,
  children,
  includeBottomGap = true,
  sx = {},
}: FormSectionProps) {
  return (
    <Stack mb={includeBottomGap ? 3 : 0} sx={sx}>
      <T variant="subtitle2" mb={2}>
        {title}
      </T>
      {children}
    </Stack>
  );
}
