import AchIcon from '@mui/icons-material/AccountBalance';
import WireIcon from '@mui/icons-material/Cable';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CheckIcon from '@mui/icons-material/Money';
import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  type SelectProps,
} from '@mui/material';
import { PaymentMethod } from 'dtos';
import { useField, useFormikContext } from 'formik';
import type * as React from 'react';
import { getPaymentMethodDisplayName } from 'utils/client/paymentMethods';

type PaymentMethodSelectorProps<T extends string = string> = {
  name: T;
  label: string;
  required?: boolean;
  icon?: React.ReactNode;
  hideCreditCard?: boolean;
} & Omit<SelectProps, 'name'>;

export default function PaymentMethodSelector<T extends string = string>({
  name,
  label,
  required,
  hideCreditCard = false,
  multiple = false,
  disabled = false,
  ...rest
}: PaymentMethodSelectorProps<T>) {
  const [field, meta, helpers] = useField(name);
  const { submitCount } = useFormikContext();

  const methods = [
    {
      id: PaymentMethod.ACH,
      name: getPaymentMethodDisplayName(PaymentMethod.ACH),
      icon: <AchIcon fontSize="small" />,
    },
    {
      id: PaymentMethod.CHECK,
      name: getPaymentMethodDisplayName(PaymentMethod.CHECK),
      icon: <CheckIcon fontSize="small" />,
    },
    ...(hideCreditCard
      ? []
      : [
          {
            id: PaymentMethod.CREDIT_CARD,
            name: getPaymentMethodDisplayName(PaymentMethod.CREDIT_CARD),
            icon: <CreditCardIcon fontSize="small" />,
          },
        ]),
    {
      id: PaymentMethod.WIRE,
      name: getPaymentMethodDisplayName(PaymentMethod.WIRE),
      icon: <WireIcon fontSize="small" />,
    },
  ];

  const handleMultiselectDelete =
    (methodId: PaymentMethod) => (event: React.MouseEvent) => {
      helpers.setValue(
        field.value.filter((id: PaymentMethod) => id !== methodId),
      );
    };

  return (
    <FormControl
      required={required}
      fullWidth
      error={submitCount > 0 && !!meta.error}
    >
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Select
        {...rest}
        {...field}
        multiple={multiple}
        disabled={disabled}
        value={field.value || ''}
        label={label}
        renderValue={(m) => {
          if (multiple) {
            return (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {(m as PaymentMethod[]).map((methodId) => {
                  const method = methods.find((m) => m.id === methodId);
                  return (
                    <Chip
                      size="small"
                      key={methodId}
                      icon={method?.icon}
                      label={method?.name}
                      onMouseDown={(event) => {
                        //important to prevent the select from intercepting the click
                        event.stopPropagation();
                      }}
                      {...(disabled
                        ? {}
                        : { onDelete: handleMultiselectDelete(methodId) })}
                    />
                  );
                })}
              </Box>
            );
          }
          const current = methods.find((x) => x.id === m);
          return (
            <MenuItem
              disableRipple
              disableTouchRipple
              sx={{
                p: 0,
                m: 0,
                '&:hover': { backgroundColor: 'transparent' },
              }}
            >
              {current?.icon && <ListItemIcon>{current.icon}</ListItemIcon>}
              <ListItemText primary={current?.name || m} />
            </MenuItem>
          );
        }}
      >
        {methods.map((m) => (
          <MenuItem key={m.id} value={m.id}>
            {m.icon && <ListItemIcon>{m.icon}</ListItemIcon>}
            <ListItemText primary={m.name} />
          </MenuItem>
        ))}
      </Select>
      {submitCount > 0 && meta.error && (
        <FormHelperText>{meta.error}</FormHelperText>
      )}
    </FormControl>
  );
}
