import AddIcon from '@mui/icons-material/Add';
import { Box, Button, ListSubheader, Stack } from '@mui/material';
import TextInputDialog from 'components/common/TextInputDialog';
import { generateColorForId } from 'dtos';
import React from 'react';
import ClientGroupListItem from './client/groups/ClientGroupListItem';
import ClientGroupsDialog from './client/groups/dialogs/ClientGroupsDialog';

type ClientFormGroupsSectionProps = {
  client: {
    firstName: string;
    middleName: string;
    lastName: string;
  };
  groups: MinimalGroup[];
  setGroups: (groups: MinimalGroup[]) => void;
};

const generateGroupTemplate = (
  client: ClientFormGroupsSectionProps['client'],
  groupName: string,
): MinimalGroup => ({
  id: '',
  name: groupName,
  color: generateColorForId(''),
  members: [
    {
      id: '',
      isPrimary: true,
      color: generateColorForId(''),
      name: [client.firstName, client.middleName, client.lastName]
        .filter(Boolean)
        .join(' '),
      firstName: client.firstName,
      lastName: client.lastName,
    },
  ],
  memberCount: 1,
});

export default function ClientFormGroupsSection({
  client,
  groups,
  setGroups,
}: ClientFormGroupsSectionProps) {
  const [isCreating, setIsCreating] = React.useState(false);
  const [isAddingToExisting, setIsAddingToExisting] = React.useState(false);
  return (
    <>
      <Stack>
        <ListSubheader sx={{ px: 0, py: 0, lineHeight: '32px' }}>
          Groups
        </ListSubheader>
        <Stack spacing={2}>
          <Box>
            {groups.map((group, index) => (
              <ClientGroupListItem
                key={index}
                showPrimaryIndicator={!group.id} // if creating a group, then this is the primary member
                showAddAndRemoveButtons
                onRemoveFromGroupClick={() => {
                  setGroups(groups.filter((_, i) => i !== index));
                }}
                group={
                  group.id ? group : generateGroupTemplate(client, group.name)
                }
                clientId=""
                isClientMember={true}
                divider={index < groups.length - 1}
              />
            ))}
          </Box>
          <Stack direction="row" spacing={1}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              startIcon={<AddIcon />}
              disableElevation
              onClick={() => setIsCreating(true)}
            >
              New Group
            </Button>
            <Button
              variant="contained"
              color="inherit"
              fullWidth
              disableElevation
              onClick={() => setIsAddingToExisting(true)}
            >
              Add to Existing
            </Button>
          </Stack>
        </Stack>
      </Stack>
      {isCreating && (
        <TextInputDialog
          open={isCreating}
          onClose={() => setIsCreating(false)}
          title="New Group"
          onSubmit={(name) => {
            const newGroup = generateGroupTemplate(client, name);

            const newGroups = [...groups.filter((g) => !g.id), newGroup];
            const existingGroups = groups.filter((g) => g.id);

            setGroups([...newGroups, ...existingGroups]);
            setIsCreating(false);
          }}
        />
      )}
      {isAddingToExisting && (
        <ClientGroupsDialog
          open={isAddingToExisting}
          onClose={() => setIsAddingToExisting(false)}
          clientId=""
          clientSideOnly={true}
          defaultGroups={groups.filter((g) => g.id)} // this dialog doesn't care about our new groups
          setDefaultGroups={(existingGroups) => {
            const newGroups = [...groups.filter((g) => !g.id)];
            setGroups([...newGroups, ...existingGroups]);
          }}
        />
      )}
    </>
  );
}
