import {
  type ClientActiveDto,
  type ClientCreateRequestDto,
  type ClientFlagListResponseDto,
  type ClientFlagResponseDto,
  type ClientFlagType,
  type ClientGroupResponseDto,
  type ClientNotesDto,
  type ClientProfileAddressesDto,
  type ClientProfileBasicInfoDto,
  type ClientProfileCorporateInfoDto,
  type ClientProfileCreditCardCreateRequestDto,
  type ClientProfileCreditCardDto,
  type ClientProfileCreditCardResponseDto,
  type ClientProfileCreditCardUpdateRequestDto,
  type ClientProfileDatesDto,
  type ClientProfileHealthInfoDto,
  type ClientProfileMembershipDto,
  type ClientProfilePassportInfoDto,
  type ClientProfilePreferenceInfoDto,
  type ClientProfileProgramsDto,
  type ClientProfileRelationshipsDto,
  type ClientProfileResponseDto,
  type ClientProfileSocialInfoDto,
  type ClientResponseDto,
  type ClientTagDto,
  type ClientUpdateRequestDto,
  type ClientWithGroupsResponseDto,
  type GiftCardCreateRequestDto,
  type GiftCardResponseDto,
  type GiftCardUpdateRequestDto,
  GroupType,
  type StatusResponseDto,
} from 'dtos';
import type { UpdateClientsAddressRequestDto } from 'pages/api/clients/copy-address';
import type { PaginatedDataResponse, SortModel } from 'types/paginatedData';
import ky from 'utils/client/ky';

export const getClient = (clientId: string) =>
  ky.get(`/api/clients/${clientId}`).json<ClientResponseDto>();

export const getClientGroups = (clientId: string) =>
  ky
    .get(
      `/api/groups?clientId=${clientId}&type=${GroupType.LEISURE}&page=0&pageSize=100000`,
    )
    .json<PaginatedDataResponse<ClientGroupResponseDto>>();

export const getClientWithPassportSignedUrl = (clientId: string) =>
  ky
    .get(`/api/clients/${clientId}?includePassportSignedUrl=true`)
    .json<ClientProfileResponseDto>();

export const getClientPassportImage = (clientId: string) =>
  ky.get(`/api/clients/${clientId}/passport`).blob();

export const deleteClientPassport = ({
  clientId,
}: {
  clientId: string;
}) => ky.delete(`/api/clients/${clientId}/passport`).json<StatusResponseDto>();

type GetClientsProps = {
  query?: string;
  tripId?: string;
  page?: number;
  pageSize?: number;
  sortModel: SortModel;
  whatFor?: string;
  active?: string;
  groupId?: string;
};
export const getClients = ({
  query = '',
  page = 0,
  pageSize = 20,
  sortModel: { sort, sortDirection },
  whatFor,
  active,
  groupId,
}: GetClientsProps): Promise<ClientWithGroupsResponseDto[]> => {
  const queryParams = new URLSearchParams();
  queryParams.set('query', query);
  queryParams.set('page', page.toString());
  queryParams.set('pageSize', pageSize.toString());
  queryParams.set('sort', sort);
  queryParams.set('sortDirection', sortDirection);
  whatFor && queryParams.set('whatFor', whatFor);
  active && queryParams.set('active', active);
  groupId && queryParams.set('groupId', groupId);

  return ky
    .get(`/api/clients?${queryParams.toString()}`)
    .json<PaginatedDataResponse<ClientWithGroupsResponseDto>>()
    .then((json) => json.data);
};

export const createClient = (client: ClientCreateRequestDto) =>
  ky.post('/api/clients', { json: client }).json<ClientProfileResponseDto>();

export const updateClient = (
  client: ClientUpdateRequestDto,
  clientId: string,
) => ky.put(`/api/clients/${clientId}`, { json: client }).json();

export const updateClientNotes = (data: ClientNotesDto, clientId: string) =>
  ky.put(`/api/clients/${clientId}/notes`, { json: data }).json();

type ClientProfileSection =
  | 'basic-info'
  | 'addresses2'
  | 'corporate'
  | 'credit-cards'
  | 'dates'
  | 'health'
  | 'passport'
  | 'preferences'
  | 'programs'
  | 'relationships'
  | 'socials';
type ClientProfileSectionDto =
  | ClientProfileBasicInfoDto
  | ClientProfileAddressesDto
  | ClientProfileCorporateInfoDto
  | ClientProfileCreditCardDto
  | ClientProfileDatesDto
  | ClientProfileHealthInfoDto
  | ClientProfilePassportInfoDto
  | ClientProfilePreferenceInfoDto
  | ClientProfileProgramsDto
  | ClientProfileMembershipDto
  | ClientProfileRelationshipsDto
  | ClientProfileSocialInfoDto;

export const updateClientTag = ({
  clientId,
  clientTag,
}: {
  clientId: string;
  clientTag: ClientTagDto;
}) =>
  ky
    .put(`/api/clients/${clientId}/tag`, { json: clientTag })
    .json<ClientTagDto>();

export const updateClientIsActive = ({
  clientId,
  clientIsActive,
}: {
  clientId: string;
  clientIsActive: boolean;
}) =>
  ky
    .put(`/api/clients/${clientId}/active`, {
      json: { isActive: clientIsActive },
    })
    .json<ClientActiveDto>();

export const updateClientProfileSection = <T extends ClientProfileSectionDto>(
  clientId: string,
  section: ClientProfileSection,
  payload: T,
) => ky.put(`/api/clients/${clientId}/${section}`, { json: payload }).json<T>();

export const createClientMarketingFlag = ({
  clientId,
  key,
  type,
}: {
  clientId: string;
  key: string;
  type: ClientFlagType;
}) =>
  ky
    .put(`/api/clients/${clientId}/flags/${key}`, { json: { type } })
    .json<ClientFlagResponseDto>();

export const deleteClientMarketingFlag = ({
  clientId,
  key,
}: {
  clientId: string;
  key: string;
}) =>
  ky.delete(`/api/clients/${clientId}/flags/${key}`).json<{ status: string }>();

export const getClientMarketingFlags = ({
  clientId,
  flagType,
}: {
  clientId: string;
  flagType: ClientFlagType;
}) =>
  ky
    .get(`/api/clients/${clientId}/flags?type=${flagType}`)
    .json<ClientFlagListResponseDto>()
    .then((json) => json.data);

export const createClientProfileCreditCard = ({
  clientId,
  card,
}: { clientId: string; card: ClientProfileCreditCardCreateRequestDto }) =>
  ky
    .post(`/api/clients/${clientId}/credit-cards`, { json: card })
    .json<ClientProfileCreditCardResponseDto>();

export const updateClientProfileCreditCard = ({
  clientId,
  card,
}: {
  clientId: string;
  card: ClientProfileCreditCardUpdateRequestDto;
}) =>
  ky
    .put(
      `/api/clients/${clientId}/credit-cards/${card.clientProfileCreditCardId}`,
      { json: card },
    )
    .json<ClientProfileCreditCardResponseDto>();

export const deleteClientProfileCreditCard = ({
  clientId,
  cardId,
}: {
  clientId: string;
  cardId: string;
}) => ky.delete(`/api/clients/${clientId}/credit-cards/${cardId}`).json();

export const createClientGiftCard = ({
  card,
}: { card: GiftCardCreateRequestDto }) =>
  ky.post('/api/gift-cards', { json: card }).json<GiftCardResponseDto>();

export const updateClientGiftCard = ({
  cardId,
  card,
}: {
  cardId: string;
  card: GiftCardUpdateRequestDto;
}) =>
  ky
    .put(`/api/gift-cards/${cardId}`, { json: card })
    .json<GiftCardResponseDto>();

export const transferClientGiftCard = ({
  cardId,
  clientId,
  groupId,
}: {
  cardId: string;
  clientId?: string;
  groupId?: string;
}) =>
  ky
    .post(`/api/gift-cards/${cardId}/transfer`, {
      json: clientId ? { clientId } : { groupId },
    })
    .json<GiftCardResponseDto>();

export const deleteClientGiftCard = ({
  cardId,
}: {
  cardId: string;
}) => ky.delete(`/api/gift-cards/${cardId}`).json();

export const deleteClientAddress = ({
  clientId,
  addressId,
}: {
  clientId: string;
  addressId: string;
}) =>
  ky
    .delete(`/api/clients/${clientId}/addresses/${addressId}`)
    .json<StatusResponseDto>();

export const copyPrimaryAddressToClients = async (
  request: UpdateClientsAddressRequestDto,
) => ky.put('/api/clients/copy-address', { json: request }).json();
