import { Box, Chip, type SxProps, type Theme, debounce } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import type { ListItem } from 'components/types';
import type { SupplierResponseDto } from 'dtos';
import { useField, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

import { getSuppliers } from 'requests/suppliers';

type SupplierMultiselectProps<T extends string = string> = {
  name: T;
  label: string;
  required?: boolean;
  onChange?: (value: { id: string; name: string }[]) => void;
  sx?: SxProps<Theme> | undefined;
  isParent?: boolean;
  isRetired?: boolean;
  disabled?: boolean;
};

export default function SupplierMultiselect<T extends string = string>({
  name,
  label,
  required,
  onChange,
  sx,
  isParent,
  isRetired,
  disabled = false,
}: SupplierMultiselectProps<T>) {
  const [field, meta, helpers] = useField<{ id: string; name: string }[]>(name);
  const { submitCount } = useFormikContext();

  const [options, setOptions] = useState<ListItem[]>([]);

  const debouncedGetSuppliers = debounce(async (query: string) => {
    const newOptions = await getSuppliers({
      query: query || '',
      pageSize: 500,
      isParent,
      isRetired,
    });

    setOptions(
      newOptions.map((opt: SupplierResponseDto) => ({
        id: opt.id,
        name: opt.name,
      })),
    );
  }, 200);

  useEffect(() => void debouncedGetSuppliers(''), []);

  return (
    <>
      <Autocomplete
        {...field}
        multiple
        fullWidth
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        sx={sx}
        disabled={disabled}
        options={options}
        onInputChange={async (_event, newInputValue) =>
          await debouncedGetSuppliers(newInputValue)
        }
        onChange={(_event, newSuppliers) => {
          const supplier = newSuppliers as ListItem[];

          const newValues = supplier.map((s) => ({
            id: s.id,
            name: s.name,
          }));

          if (onChange) onChange(newValues);
          else helpers.setValue(newValues);
        }}
        getOptionLabel={(option) => {
          return (option as ListItem)?.name;
        }}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment:
                field.value?.length > 0 ? (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    {field.value.map((supplier) => (
                      <Chip
                        key={supplier.id}
                        label={supplier.name}
                        onDelete={() => {
                          const newValue = field.value.filter(
                            (s) => s.id !== supplier.id,
                          );
                          helpers.setValue(newValue);
                          onChange?.(newValue);
                        }}
                      />
                    ))}
                  </Box>
                ) : undefined,
            }}
            onFocus={() => {
              helpers.setTouched(true);
            }}
            onBlur={() => {
              helpers.setTouched(false);
            }}
            label={label}
            error={submitCount > 0 && !!meta.error}
            helperText={submitCount > 0 && meta.error}
            required={required}
          />
        )}
      />
    </>
  );
}
