import { PaymentMethod } from 'dtos';

export function getPaymentMethodDisplayName(method: PaymentMethod): string {
  switch (method) {
    case PaymentMethod.ACH:
      return 'ACH';
    case PaymentMethod.CHECK:
      return 'Check';
    case PaymentMethod.CREDIT_CARD:
      return 'Credit Card';
    case PaymentMethod.WIRE:
      return 'Wire Transfer';
    default:
      return method;
  }
}
