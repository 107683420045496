import {
  Checkbox as MuiCheckbox,
  type CheckboxProps as MuiCheckboxProps,
  FormControlLabel as MuiFormControlLabel,
} from '@mui/material';
import { useField } from 'formik';

type CheckboxProps<T extends string = string> = {
  name: T;
} & Omit<MuiCheckboxProps, 'name'>;

export default function Checkbox<T extends string = string>(
  props: CheckboxProps<T>,
) {
  const { name } = props;
  const [field] = useField(name);

  return (
    <MuiCheckbox {...props} {...field} checked={field.value} name={name} />
  );
}
