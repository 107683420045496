import type { ExchangeRate } from '@prisma/client';
import moment from 'moment';
import { z } from 'zod';

export const ExchangeRateRequestDto = z.object({
  source: z.string(),
  date: z.string().optional(),
});
export type ExchangeRateRequestDto = z.infer<typeof ExchangeRateRequestDto>;

export const ExchangeRateDto = z.object({
  source: z.string(),
  target: z.string(),
  rate: z.number(),
  date: z.string(),
});
export type ExchangeRateDto = z.infer<typeof ExchangeRateDto>;

export const exchangeRateToDto = (
  exchangeRate: ExchangeRate,
): ExchangeRateDto => ({
  source: exchangeRate.source,
  target: exchangeRate.target,
  rate: exchangeRate.rate,
  date: moment.utc(exchangeRate.date).format('YYYY-MM-DD'),
});
