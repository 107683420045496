import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select as MUISelect,
  MenuItem,
} from '@mui/material';
import type { SxProps, Theme } from '@mui/system';
import { useField, useFormikContext } from 'formik';
import type React from 'react';

export type SelectProps<T extends string = string> = {
  name: T;
  label: string;
  options: { label: string; value: string }[];
  onChange?: (value: string) => void;
  required?: boolean;
  sx?: SxProps<Theme> | undefined;
  disabled?: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  variant?: 'standard' | 'outlined' | 'filled';
};

const Select = <T extends string = string>({
  name,
  label,
  options,
  onChange = () => {},
  required,
  sx,
  disabled = false,
  startAdornment,
  endAdornment,
  variant = 'outlined',
}: SelectProps<T>) => {
  const [field, meta, helpers] = useField(name);
  const { submitCount } = useFormikContext();

  return (
    <FormControl
      fullWidth
      variant={variant}
      error={submitCount > 0 && !!meta.error}
      sx={sx}
      disabled={disabled}
    >
      <InputLabel
        id={`${name}-label`}
        required={required}
        shrink={!!field.value}
      >
        {label}
      </InputLabel>
      <MUISelect
        {...field}
        labelId={`${name}-label`}
        label={label}
        notched={!!field.value}
        onChange={(event) => {
          helpers.setValue(event.target.value);
          onChange?.(event.target.value as string);
        }}
        onBlur={field.onBlur}
        placeholder="" //prevent placeholder from obscuring selected label
        {...(startAdornment && { startAdornment })}
        {...(endAdornment && { endAdornment })}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MUISelect>
      {submitCount > 0 && !!meta.error && (
        <FormHelperText>{meta.error}</FormHelperText>
      )}
    </FormControl>
  );
};

export default Select;
