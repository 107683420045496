import { List } from '@mui/material';
import type { ClientGroupCreateRequestDto, GroupResponseDto } from 'dtos';
import ClientGroupListItem from './ClientGroupListItem';

type AllGroupsListProps = {
  clientId: string;
  groups: MinimalGroup[];
  clientGroups: MinimalGroup[];
  showPrimaryIndicator?: boolean;
  onAddToGroupClick?: (
    groupId: string,
    client: ClientGroupCreateRequestDto,
  ) => void;
  onRemoveFromGroupClick?: (groupId: string, clientId: string) => void;
  onListItemClick?: (groupId: string) => void;
};

export default function AllGroupsList({
  clientId,
  groups: allGroups,
  clientGroups,
  showPrimaryIndicator = false,
  onAddToGroupClick,
  onRemoveFromGroupClick,
  onListItemClick,
}: AllGroupsListProps) {
  const nonPrimaryGroups = allGroups.filter(
    (group) => group.primaryMemberId !== clientId,
  );

  return (
    <List>
      {nonPrimaryGroups
        .filter((group) => group.primaryMemberId !== clientId)
        .map((group, idx) => (
          <ClientGroupListItem
            clientId={clientId}
            group={group}
            isClientMember={clientGroups.some((cg) => cg.id === group.id)}
            key={group.id}
            showPrimaryIndicator={
              showPrimaryIndicator && group.primaryMemberId === clientId
            }
            showAddAndRemoveButtons={group.primaryMemberId !== clientId}
            onAddToGroupClick={onAddToGroupClick}
            onRemoveFromGroupClick={onRemoveFromGroupClick}
            divider={idx !== nonPrimaryGroups.length - 1}
            onClick={onListItemClick}
          />
        ))}
    </List>
  );
}
