import type {
  BulkMatchSupplierPaymentsRequestDto,
  BulkMatchSupplierPaymentsResponseDto,
  ScoredBookingResponseDto,
  SupplierBookingForPaymentsPaginatedRequestDto,
  SupplierCreateRequestDto,
  SupplierNotesDto,
  SupplierResponseDto,
  SupplierUpdateRequestDto,
} from 'dtos';
import type { Options } from 'ky';
import type { PaginatedDataResponse } from 'types/paginatedData';
import { getUrlSearchParams } from 'utils/client/fetch';
import ky from 'utils/client/ky';

export const createSupplier = (supplier: SupplierCreateRequestDto) =>
  ky.post('/api/suppliers', { json: supplier }).json<SupplierResponseDto>();

export const updateSupplier = (
  supplier: SupplierUpdateRequestDto,
  id: string,
) =>
  ky
    .put(`/api/suppliers/${id}`, { json: supplier })
    .json<SupplierResponseDto>();

export const updateSupplierStatus = ({
  id,
  active,
}: { id: string; active: boolean }) =>
  ky
    .put(`/api/suppliers/${id}/status`, { json: { isRetired: !active } })
    .json<SupplierResponseDto>();

export const deleteSupplier = (id: string) =>
  ky.delete(`/api/suppliers/${id}`).json<SupplierResponseDto>();

export const fetchReporting = async (id: string) => {
  const [overview, yearlyStats] = await Promise.all([
    ky.get(`/api/suppliers/${id}/reporting/overview`),
    ky.get(`/api/suppliers/${id}/reporting/yearly-stats`),
  ]);

  return {
    overview: await overview.json(),
    yearlyStats: await yearlyStats.json(),
  };
};

interface GetSuppliersParams {
  query?: string;
  page?: number;
  pageSize?: number;
  getAll?: boolean;
  sort?: string;
  sortDirection?: string;
  isParent?: boolean;
  isRetired?: boolean;
}

export const getSuppliers = async ({
  query = '',
  getAll = false,
  page = 0,
  pageSize = 20,
  sort = 'name',
  sortDirection = 'asc',
  isParent,
  isRetired,
}: GetSuppliersParams): Promise<SupplierResponseDto[]> => {
  const queryParams = new URLSearchParams();
  queryParams.set('query', query);
  queryParams.set('page', page.toString());
  queryParams.set('pageSize', getAll ? '1000000' : pageSize.toString());
  queryParams.set('sort', sort);
  queryParams.set('sortDirection', sortDirection);
  queryParams.set('queryOn', 'name');
  isParent !== undefined &&
    queryParams.set('isParent', isParent ? 'true' : 'false');
  isRetired !== undefined &&
    queryParams.set('isRetired', isRetired ? 'true' : 'false');

  const suppliersResponse = await ky.get(
    `/api/suppliers?${queryParams.toString()}`,
  );
  return suppliersResponse
    .json<PaginatedDataResponse<SupplierResponseDto>>()
    .then((json) => json.data);
};

type GetSupplierProps = {
  id: string;
};

export const getSupplier = ({
  id,
}: GetSupplierProps): Promise<SupplierResponseDto> =>
  ky.get(`/api/suppliers/${id}`).json<SupplierResponseDto>();

export type GetSupplierBookingsProps =
  SupplierBookingForPaymentsPaginatedRequestDto & {
    supplierId: string;
    fetchOptions?: Options;
  };

export const getSupplierBookings = async ({
  supplierId,
  query = '',
  page = 0,
  pageSize = 20,
  receivedCommission,
  commissionReceivedDate,
  confirmationNumber,
  advisorId,
  clientId,
  checkIn,
  checkOut,
  commissionableValue,
  notes,
  total,
  advisorName,
  clientName,
  supplierName,
  fetchOptions,
}: GetSupplierBookingsProps): Promise<ScoredBookingResponseDto[]> => {
  const queryParams = getUrlSearchParams({
    query,
    page: page.toString(),
    pageSize: pageSize.toString(),
    commissionReceivedDate,
    confirmationNumber,
    receivedCommission: receivedCommission?.toString(),
    clientId,
    advisorId,
    clientName,
    advisorName,
    supplierName,
    total,
    commissionableValue,
    checkIn,
    checkOut,
    notes,
  });

  const url = `api/suppliers/${supplierId}/bookings-for-payments?${queryParams.toString()}`;
  const supplierBookingsResponse = await ky.get(url, fetchOptions);
  return supplierBookingsResponse.json<ScoredBookingResponseDto[]>();
};

export const bulkMatchSupplierPayments = (
  supplierId: string,
  request: BulkMatchSupplierPaymentsRequestDto,
): Promise<BulkMatchSupplierPaymentsResponseDto> =>
  ky
    .post(`/api/suppliers/${supplierId}/bookings-for-payments`, {
      json: request,
    })
    .json<BulkMatchSupplierPaymentsResponseDto>();

export const updateSupplierNotes = (
  data: SupplierNotesDto,
  supplierId: string,
) =>
  ky
    .put(`/api/suppliers/${supplierId}/notes`, { json: data })
    .json<SupplierNotesDto>();
