import Button from '@mui/material/Button';
import Dialog, { type DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle, { type DialogTitleProps } from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ConfirmationDialog } from 'components/common/ConfirmationDialog';
import { Formik, type FormikValues } from 'formik';
import type { FormikProps as FP } from 'formik/dist/types';
import { enqueueSnackbar as toast } from 'notistack';
import React, { type ReactNode } from 'react';
import type { ExtendedFormSchema } from 'utils/client/types';
import type { ObjectSchema, ObjectShape } from 'yup';

type ConfirmationDialogProps = {
  message: string;
  confirmButtonMessage: string;
};

export type ExtendedFormDialogProps<
  InputDtoType,
  CreateOrUpdateDtoType,
  V extends FormikValues,
  Schema extends
    | ObjectSchema<ObjectShape>
    | ((context: C) => ObjectSchema<ObjectShape>),
  C extends object,
> = {
  title: string;
  titleExtraComponent?: ReactNode;
  open: boolean;
  // TODO: 👇🏼 this should be renamed to onClose since that's what is actually doing
  setOpen: (isOpen: boolean) => Promise<void> | void;
  entity?: InputDtoType;
  saveButtonText?: string;
  FormComponent: React.ComponentType<{
    initialValues: V;
    isSubmitting: boolean;
    context: C;
  }>;
  context?: C;
  onSave: (data: CreateOrUpdateDtoType) => Promise<InputDtoType>;
  onSuccess: (entity: InputDtoType) => Promise<void> | void;
  formSchema: ExtendedFormSchema<
    InputDtoType,
    CreateOrUpdateDtoType,
    V,
    Schema,
    C
  >;
  dialogProps?: Partial<DialogProps>;
  dialogTitleProps?: Partial<DialogTitleProps>;
  width?: string | number;
  includeTopPadding?: boolean;
  disabled?: boolean;
  confirmation?: ConfirmationDialogProps;
  submissionDelay?: number;
  HeaderComponent?: ReactNode;
  showSuccessToast?: boolean;
  fullWidth?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  asyncValidation?: ({
    values,
    context,
  }: {
    values: V;
    context?: C;
  }) => Promise<boolean>;
};

export default function ExtendedFormDialog<
  InputDtoType,
  CreateOrUpdateDtoType,
  V extends FormikValues,
  Schema extends
    | ObjectSchema<ObjectShape>
    | ((context: C) => ObjectSchema<ObjectShape>),
  C extends object,
>({
  title,
  titleExtraComponent,
  open,
  setOpen,
  entity,
  onSuccess,
  FormComponent,
  context,
  formSchema,
  onSave,
  saveButtonText = 'Save',
  dialogProps = {},
  dialogTitleProps = {},
  width = 850,
  includeTopPadding = true,
  disabled = false,
  confirmation,
  HeaderComponent,
  asyncValidation,
  showSuccessToast = true,
  fullWidth = false,
  maxWidth,
}: ExtendedFormDialogProps<InputDtoType, CreateOrUpdateDtoType, V, Schema, C>) {
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);

  const theme = useTheme();

  const [runningAsyncValidation, setRunningAsyncValidation] =
    React.useState(false);

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const close = () => {
    void setOpen(false);
  };

  const onSavePressed = async (formik: FP<V>) => {
    if (asyncValidation) {
      setRunningAsyncValidation(true);
      const valid = await asyncValidation({
        values: formik.values,
        context,
      });

      setRunningAsyncValidation(false);
      if (!valid) return false;
    }

    if (confirmation) {
      await formik.validateForm();
      if (formik.isValid) setConfirmationOpen(true);
    } else {
      await formik.submitForm();
    }
    return;
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={close}
        PaperProps={{ sx: fullWidth ? {} : { width, maxWidth: width } }}
        {...dialogProps}
        disableRestoreFocus //this is needed to allow autoFocus for any form fields
      >
        {HeaderComponent}
        <DialogTitle
          borderBottom="1px solid"
          borderColor="divider"
          mb={3}
          {...dialogTitleProps}
        >
          {title}
          {titleExtraComponent ? titleExtraComponent : undefined}
        </DialogTitle>
        <Formik<V>
          initialValues={formSchema.getDefaultValues(
            entity,
            context ?? ({} as C),
          )}
          enableReinitialize={true}
          validationSchema={
            typeof formSchema.schema === 'function'
              ? formSchema.schema(context || ({} as C))
              : formSchema.schema
          }
          onSubmit={async (
            values,
            { validateForm, resetForm, setSubmitting },
          ) => {
            await validateForm()
              .then(async () => {
                setSubmitting(true);
                const data = formSchema.getEntityFromFormValues(
                  values,
                  context ?? ({} as C),
                );

                try {
                  const result = await onSave(data);

                  await onSuccess(result as InputDtoType);

                  if (showSuccessToast)
                    toast('Your data has been saved successfully');

                  //still need this because the dialog close is animated
                  window.setTimeout(() => resetForm(), 1000);
                } catch (error) {}
              })
              .catch(console.error)
              .finally(() => setSubmitting(false));
          }}
        >
          {(formik) => {
            return (
              <>
                <DialogContent
                  sx={(theme) => ({
                    pt: includeTopPadding ? `${theme.spacing(1)}!important` : 0,
                  })}
                >
                  <FormComponent
                    initialValues={formik.values}
                    isSubmitting={formik.isSubmitting}
                    context={context as C}
                  />
                </DialogContent>
                <DialogActions
                  sx={{ borderTop: `1px solid ${theme.palette.divider}` }}
                >
                  <Button disabled={formik.isSubmitting} onClick={close}>
                    Cancel
                  </Button>
                  <Button
                    disabled={
                      runningAsyncValidation || formik.isSubmitting || disabled
                    }
                    variant="contained"
                    onClick={() => void onSavePressed(formik)}
                  >
                    {saveButtonText}
                  </Button>
                </DialogActions>

                {confirmation && (
                  <ConfirmationDialog
                    title="Confirm Changes"
                    confirmButtonMessage={confirmation.confirmButtonMessage}
                    message={confirmation.message}
                    open={confirmationOpen}
                    setOpen={setConfirmationOpen}
                    onConfirm={async () => {
                      setConfirmationOpen(false);
                      await formik.submitForm();
                    }}
                    onCancel={() => {
                      setConfirmationOpen(false);
                      close();
                    }}
                  />
                )}
              </>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
}
