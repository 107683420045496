import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import type { SxProps, Theme } from '@mui/system';
import { useField, useFormikContext } from 'formik';
import type React from 'react';

type ComboBoxProps<T extends string = string> = {
  name: T;
  label: string;
  placeholder?: string;
  options: string[];
  multiple?: boolean;
  required?: boolean;
  sx?: SxProps<Theme> | undefined;
  fullWidth?: boolean;
};

const ComboBox = <T extends string>({
  name,
  label,
  placeholder,
  required,
  options,
  sx,
  multiple,
  fullWidth,
}: ComboBoxProps<T>) => {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  const { submitCount } = useFormikContext();

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (multiple) {
      if (!Array.isArray(field.value)) {
        setValue([event.target.value]);
      } else if (
        !field.value.includes(event.target.value) &&
        event.target.value?.trim()
      ) {
        setValue([...field.value, event.target.value]);
      }
    } else {
      setValue(event.target.value);
    }

    field.onBlur(event);
  };

  return (
    <Autocomplete
      {...field}
      sx={sx}
      onChange={(_, newValue) => {
        setValue((newValue as string) || '');
      }}
      onInputChange={(event, newInputValue) => {
        if (multiple && event.target instanceof HTMLInputElement) {
          if (newInputValue.endsWith(',')) {
            event.target.value = newInputValue.slice(0, -1);
            event.target.blur();
            event.target.focus();
          }
        }
      }}
      freeSolo
      multiple={multiple}
      selectOnFocus
      fullWidth={fullWidth}
      clearOnBlur={multiple}
      onBlur={handleBlur}
      handleHomeEndKeys
      options={options}
      renderOption={(props, option) => (
        <li {...props} key={option}>
          {option}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={
            (multiple && field.value.length === 0) ||
            (!multiple && !field.value)
              ? placeholder
              : ''
          }
          id={name}
          name={name}
          required={required}
          error={submitCount > 0 && !!meta.error}
          helperText={submitCount > 0 && meta.error}
        />
      )}
    />
  );
};

export default ComboBox;
