import type { ClientWithGroupsResponseDto, GroupResponseDto } from 'dtos';
import { useEffect, useState } from 'react';
import { getClients } from 'requests/clients';
import { getCorporateGroups } from 'requests/groups';

type UseClientsAndGroupsProps = {
  initialValue: string;
  includeCorporateGroups?: boolean;
  callback?: (
    clients: ClientWithGroupsResponseDto[],
    groups: GroupResponseDto[],
  ) => void;
};

export default function useDebouncedClientsAndCorporateGroups({
  initialValue,
  includeCorporateGroups = false,
  callback,
}: UseClientsAndGroupsProps) {
  const [query, setQuery] = useState(initialValue);
  const [timeoutId, setTimeoutId] = useState<number>();

  const resetTimeout = () => {
    if (timeoutId) {
      window.clearTimeout(timeoutId);
      setTimeoutId(undefined);
    }
  };

  useEffect(() => {
    resetTimeout();

    const id = window.setTimeout(async () => {
      const [clients, groups = []] = await Promise.all([
        getClients({
          query,
          pageSize: 1000,
          sortModel: { sort: 'lastName', sortDirection: 'asc' },
          whatFor: 'select',
        }),
        ...(includeCorporateGroups
          ? [
              getCorporateGroups({
                query,
                pageSize: 1000,
              }),
            ]
          : []),
      ]);

      callback?.(clients, groups);
    }, 200);

    setTimeoutId(id);

    return () => {
      resetTimeout();
    };
  }, [query]);

  return { setQuery };
}
