import { Box, FormHelperText } from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import type { ClientProfileCreditCardValues } from 'components/clients/shared/giftCards/types';
import { Field, useField, useFormikContext } from 'formik';
import useTokenexHmac from 'hooks/useTokenexHmac';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

export type TokenexTextFieldProps<T extends string = string> = {
  label: string;
  name: T;
  disabled?: boolean;
  pci?: boolean;
  lastFourName?: string;
  typeName?: string;
  onTokenize?: () => void;
};

// Update the type definition for the iframeRef
type IframeType = {
  on: (
    event: string,
    callback: (data: Record<string, unknown> & { token?: string }) => void,
  ) => void;
  tokenize: () => void;
  validate: () => void;
  load: () => void;
};

export interface TokenexTextFieldRef {
  tokenize: () => void;
}

type TokenexCreditCardType =
  | 'masterCard'
  | 'americanExpress'
  | 'discover'
  | 'visa'
  | 'diners'
  | 'jcb';

const getFormattedType = (type: TokenexCreditCardType) => {
  const types = {
    masterCard: 'Mastercard',
    americanExpress: 'American Express',
    discover: 'Discover',
    visa: 'Visa',
    diners: 'Diners Club',
    jcb: 'JCB',
  };

  return types[type] || type;
};

const TokenexTextField = forwardRef<TokenexTextFieldRef, TokenexTextFieldProps>(
  (props: TokenexTextFieldProps, ref) => {
    const {
      label,
      name,
      disabled,
      pci = false,
      lastFourName,
      typeName,
      onTokenize = () => {},
    } = props;

    const [field, meta, { setValue: setFormikValue }] = useField(name);

    const { values, setValues, submitCount, getFieldHelpers, isValidating } =
      useFormikContext<ClientProfileCreditCardValues>();

    const [tokenExHasError, setTokenexHasError] = useState(false);

    const iframeRef = useRef<IframeType>(null);
    const valuesRef = useRef(values);

    useImperativeHandle(ref, () => ({
      tokenize: () => {
        iframeRef.current?.tokenize();
      },
    }));

    useEffect(() => {
      valuesRef.current = values;
    }, [values]);

    const { hmac, timestamp } = useTokenexHmac({
      tokenScheme: pci ? 'PCI' : 'GUID',
    });

    useEffect(() => {
      if (hmac && !iframeRef.current) {
        const iframeConfig = {
          authenticationKey: hmac,
          timestamp,
          origin: process.env.NEXT_PUBLIC_APP_URL || window.location.origin,
          tokenExID: process.env.NEXT_PUBLIC_TOKEN_EX_ID,
          tokenScheme: pci ? 'PCI' : 'GUID',
          pci: pci,
          inputType: 'text',
          ...(disabled && { inputDisabled: true }),
          debug: true,
          enableAutocomplete: false,
          enablePrettyFormat: pci,
          placeholder: label,
          customDataLabel: pci ? 'cc-number' : 'cc-cvc',
          enableValidateOnBlur: false,
          font: 'Roboto', //important!  can only specify one font here with no fallbacks
          styles: {
            placeholder:
              'color:rgb(0,0,0,.54);line-height:23px;-webkit-font-smoothing: antialiased;opacity:1;',
            base: `font-family: Roboto, Arial,sans-serif; 
                  padding: 18px 14px;
                  font-size: 16px;                           
                  line-height: 1.1876em;
                  color: #000;
                  box-sizing: border-box; 
                  margin: 0;               
                  position: relative; 
                  display: inline-flex; 
                  align-items: center; 
                  width: 100%; 
                  border: 1px solid #c3c4c3; 
                  border-radius: 4px; 
                  background: none; 
                  cursor: text;`,
            focus: `border: 2px solid ${deepPurple[500]};outline:0`,
            error:
              'box-shadow: 0 0 6px 0 rgba(224, 57, 57, 0.5);border: 1px solid rgba(224, 57, 57, 0.5);',
          },
        };

        //@ts-ignore-next-line
        iframeRef.current = new TokenEx.Iframe(
          `tokenex-${name}`,
          iframeConfig,
        ) as IframeType;

        const iframe = iframeRef.current;

        iframe.on(
          'tokenize',
          (data: Record<string, unknown> & { token?: string }) => {
            if (pci) {
              if (data?.token) {
                setFormikValue(data?.token);
                if (lastFourName)
                  getFieldHelpers(lastFourName).setValue(data?.lastFour);
                if (typeName)
                  getFieldHelpers(typeName).setValue(
                    getFormattedType(data?.cardType as TokenexCreditCardType),
                  );
              }
            } else {
              setFormikValue(data?.token);
            }

            onTokenize();
          },
        );

        iframe.on('change', () => {
          iframe.validate();
        });

        iframe.on('error', (data: Record<string, unknown>) => {
          console.error(`Tokenex Error:${JSON.stringify(data as unknown)}`);
        });

        iframe.on('validate', (data: Record<string, unknown>) => {
          if (!data?.isValid) {
            setTokenexHasError(true);
            if (pci) {
              setFormikValue('', false);
              if (lastFourName)
                getFieldHelpers(lastFourName).setValue('', false);
              if (typeName) getFieldHelpers(typeName).setValue('', false);
            } else {
              setFormikValue('', false);
            }
          } else {
            setTokenexHasError(false);
          }
        });

        iframe.load();
      }
    }, [hmac]);

    const hasError = tokenExHasError;

    if (!hmac || !timestamp) return null;
    return (
      <Box
        height={56}
        sx={{
          ...(disabled && { pointerEvents: 'none', backgroundColor: '#eee' }),
          '& > iframe': { height: 50 },
          marginBottom: hasError ? '12px!important' : 0,
        }}
      >
        <Field
          {...field}
          validate={() => {
            if (submitCount > 0) iframeRef.current?.validate();
          }}
          type="hidden"
        />
        <div id={`tokenex-${name}`} style={{ maxHeight: 56 }} />
        {hasError && (
          <FormHelperText error={true} sx={{ mt: '3px', pl: 1.5 }}>
            {meta.error || `${label} is a required field`}
          </FormHelperText>
        )}
      </Box>
    );
  },
);

export default TokenexTextField;
