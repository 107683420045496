import type { CreatableListItem, ListItem } from 'components/types';
import {
  type SupplierResponseDto,
  SupplierType,
  type SupplierUpdateRequestDto,
} from 'dtos';
import * as yup from 'yup';

export type SupplierFormValues = {
  name: string;
  address: string | null;
  phone: string | null;
  website: string | null;
  type: SupplierType;
  defaultCommissionPercent: number | null;
  defaultCommissionDueDateOffset: number | null;
  interfaceId?: string | null;
  isClearinghouse: boolean;
  parent: (ListItem & { type: SupplierType }) | CreatableListItem | null;
  badges: ListItem[];
  tags: string[];
  associatedSuppliers: ListItem[];
};

const schema = yup.object().shape({
  name: yup.string().required().min(3).label('Name'),
  address: yup.string().min(3).label('Address'),
  phone: yup.string().label('Phone'),
  website: yup.string().min(3).label('Website'),
  type: yup.string().min(3).label('Type'),
  defaultCommissionPercent: yup
    .number()
    .nullable()
    .min(0)
    .label('Commission Percentage'),
  defaultCommissionDueDateOffset: yup
    .number()
    .nullable()
    .label('Commission Due'),
  interfaceId: yup.string().label('Interface ID'),
  isClearinghouse: yup.boolean().label('Clearinghouse'),
  parent: yup
    .object()
    .nullable()
    .shape({
      id: yup.string(),
      name: yup.string(),
    })
    .label('Parent Supplier'),
  badges: yup.array().of(
    yup.object().shape({
      id: yup.string(),
      name: yup.string(),
    }),
  ),
  tags: yup.array().of(yup.string()),
  associatedSuppliers: yup.array().of(
    yup.object().shape({
      id: yup.string(),
      name: yup.string(),
    }),
  ),
});

const getDefaultValues = (
  supplier: Partial<SupplierResponseDto> | undefined,
): SupplierFormValues => {
  const s = supplier;
  return {
    name: s?.name || '',
    address: s?.address || '',
    phone: s?.phone || '',
    website: s?.website || '',
    type: s?.type || SupplierType.HOTEL,
    defaultCommissionPercent: s?.defaultCommissionPercent ?? null,
    defaultCommissionDueDateOffset: s?.defaultCommissionDueDateOffset ?? null,
    interfaceId: s?.interfaceId || '',
    isClearinghouse: s?.isClearinghouse ?? false,
    parent: s?.parent
      ? {
          id: s.parent.id,
          name: s.parent.name,
          type: s.parent.type,
        }
      : null,
    badges:
      s?.badges?.map((b) => ({
        id: b.id,
        name: b.name,
      })) || [],
    tags: s?.tags || [],
    associatedSuppliers:
      s?.associatedSuppliers?.map((a) => ({
        id: a.id,
        name: a.name,
      })) || [],
  };
};

const getSupplierFromFormValues = (
  values: SupplierFormValues,
): SupplierUpdateRequestDto => ({
  name: values.name,
  address: values.address || undefined,
  phone: values.phone || undefined,
  website: values.website || undefined,
  type: values.type,
  defaultCommissionPercent: values.defaultCommissionPercent ?? undefined,
  defaultCommissionDueDateOffset:
    values.defaultCommissionDueDateOffset ?? undefined,
  interfaceId: values.interfaceId || undefined,
  isClearinghouse: values.isClearinghouse,
  parentId: values.parent ? values.parent.id : undefined,
  badgeIds: values.badges?.map((b) => b.id),
  tags: values.tags,
  associatedSupplierIds: values.associatedSuppliers.map((a) => a.id),
});

export { getDefaultValues, getSupplierFromFormValues, schema };
