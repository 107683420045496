import type { FormikValues } from 'formik';
import React from 'react';
import type { ObjectSchema, ObjectShape } from 'yup';
import ExtendedFormDialog, {
  type ExtendedFormDialogProps,
} from './ExtendedFormDialog'; // Adjust the import path as needed

/**
 * @deprecated use ExtendedFormDialog instead
 */
export default function FormDialog<
  InputDtoType,
  V extends FormikValues,
  Schema extends ObjectSchema<ObjectShape>,
  C extends object = {},
>(props: ExtendedFormDialogProps<InputDtoType, InputDtoType, V, Schema, C>) {
  return <ExtendedFormDialog {...props} />;
}
