import { Autocomplete, Chip, createFilterOptions } from '@mui/material';
import type { CreatableListItem, ListItem } from 'components/types';
import { useField } from 'formik';
import useClientGroups from 'hooks/useClientGroups';
import { queryTypes, useQueryStates } from 'next-usequerystate';

import AddClientDialog from 'components/clients/individual/AddClientDialog';
import ClientMultiselectGroupsSection from './ClientMultiselectGroupsSection';
import ClientMultiselectInputField from './ClientMultiselectInputField';
import ClientMultiselectOption from './ClientMultiselectOption';
import type {
  AnyClientListItem,
  ClientCreatableListItem,
  ClientGroupsSection,
  ClientMultiselectProps,
} from './types';
import useClientMultiselectClients from './useClientMultiselectClients';

const filter = createFilterOptions<AnyClientListItem>();

const getClientOptions = (
  options: (AnyClientListItem | ClientGroupsSection)[],
) =>
  options.filter(
    (o) => (o as ClientGroupsSection).type !== 'groups-section',
  ) as AnyClientListItem[];

const ClientMultiselect = ({
  allowAdd,
  disabled = false,
  helperText,
  fieldId = 'id',
  label,
  name,
  onAddSuccess,
  onChange,
  required = false,
  showGroups = false,
  sx,
  displayEmailAndPhone = false,
  clientIdForGroupsSection,
}: ClientMultiselectProps) => {
  const [field, meta, helpers] = useField<ListItem[]>(name);
  const { options, debouncedFetch } = useClientMultiselectClients();

  const [addStatus, setAddStatus] = useQueryStates({
    addingClient: queryTypes.boolean.withDefault(false),
    clientName: queryTypes.string,
    fieldId: queryTypes.string,
  });

  const resetAddStatus = () =>
    setAddStatus({ addingClient: false, clientName: '', fieldId: '' });

  const isDialogOpen = addStatus.addingClient && addStatus.fieldId === fieldId;

  const { data: leisureGroups } = useClientGroups({
    clientId: clientIdForGroupsSection,
    includeAllMembers: true,
  });

  const groupsSection: ClientGroupsSection = {
    type: 'groups-section',
    groups: clientIdForGroupsSection ? leisureGroups ?? [] : [],
  };

  const extendedOptions = [groupsSection, ...options];

  return (
    <>
      <Autocomplete
        {...field}
        fullWidth
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        sx={sx}
        options={extendedOptions}
        freeSolo={allowAdd}
        disabled={disabled}
        forcePopupIcon
        multiple
        isOptionEqualToValue={(option, value) => {
          if (
            (option as ClientGroupsSection).type === 'groups-section' ||
            (value as ClientGroupsSection).type === 'groups-section'
          )
            return false;

          const listItem = option as CreatableListItem;
          const valueItem = value as CreatableListItem;
          return (
            listItem.id === valueItem.id ||
            listItem.name === (valueItem as ClientCreatableListItem)?.inputValue
          );
        }}
        onInputChange={(_event, newInputValue) => debouncedFetch(newInputValue)}
        onChange={(_event, newValues) => {
          const clients = newValues as CreatableListItem[];

          const lastClient: AnyClientListItem | string =
            clients[clients.length - 1];

          const isAddingClient =
            typeof lastClient === 'string' || !!lastClient?.inputValue;

          if (isAddingClient)
            void setAddStatus({
              fieldId,
              addingClient: true,
              clientName:
                lastClient?.inputValue || (lastClient as unknown as string),
            });

          if (onChange) {
            onChange(clients || null);
          } else {
            if (clients.length > 0) {
              const listItem: ListItem = {
                id: lastClient.id || '',
                name:
                  lastClient.inputValue ||
                  lastClient.name ||
                  (lastClient as unknown as string),
              };

              clients[clients.length - 1] = listItem as CreatableListItem;
            }

            if (!isAddingClient)
              helpers.setValue((clients as ListItem[]) || null);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(getClientOptions(options), params);
          const { inputValue } = params;

          // Suggest the creation of a new value
          const isExisting = getClientOptions(options).some(
            ({ name }) => inputValue === name,
          );

          if (allowAdd && inputValue !== '' && !isExisting)
            filtered.push({ id: '', inputValue, name: `Add "${inputValue}"` });

          const filteredGroupsSection = {
            ...groupsSection,
            groups: groupsSection.groups.filter((group) =>
              group.members.some((member) =>
                member.name.toLowerCase().includes(inputValue.toLowerCase()),
              ),
            ),
          };

          return [filteredGroupsSection, ...filtered];
        }}
        getOptionLabel={(option) => {
          if ((option as ClientGroupsSection).type === 'groups-section')
            return '';

          const listItem = option as AnyClientListItem | string;

          // Value selected with enter, right from the input
          if (typeof listItem === 'string') return listItem;

          // Add "xxx" option created dynamically
          if ((listItem as ClientCreatableListItem).inputValue) {
            return (listItem as ClientCreatableListItem).inputValue;
          }
          // Regular option
          return listItem.name || '';
        }}
        renderOption={(props, option) =>
          (option as ClientGroupsSection).type === 'groups-section' ? (
            <ClientMultiselectGroupsSection
              groups={(option as ClientGroupsSection).groups}
              selectedClients={field.value}
              setSelectedClients={(clients) =>
                helpers.setValue(
                  clients.filter((c) => c.id !== clientIdForGroupsSection),
                )
              }
            />
          ) : (
            <li {...props} key={(option as AnyClientListItem).id}>
              <ClientMultiselectOption
                option={option as AnyClientListItem}
                displayEmailAndPhone={displayEmailAndPhone}
                showGroups={showGroups}
              />
            </li>
          )
        }
        renderInput={(params) => (
          <ClientMultiselectInputField
            params={params}
            label={label}
            helperText={helperText}
            fieldId={fieldId}
            required={required}
            meta={meta}
            onFocus={() => helpers.setTouched(true)}
            onBlur={() => helpers.setTouched(false)}
          />
        )}
        renderTags={(values, getTagProps) =>
          values.map((option, index) => (
            <Chip
              label={
                typeof option === 'string' ? option : (option as ListItem).name
              }
              {...getTagProps({ index })}
              key={(option as ListItem).id}
            />
          ))
        }
      />
      {isDialogOpen && (
        <AddClientDialog
          open={isDialogOpen}
          entity={{
            firstName: addStatus.clientName?.split(' ')[0] ?? '',
            lastName: addStatus.clientName?.split(' ').slice(1).join(' ') ?? '',
          }}
          onSuccess={(newClient) => {
            onAddSuccess(newClient);
            resetAddStatus();
          }}
          onClose={resetAddStatus}
        />
      )}
    </>
  );
};

export default ClientMultiselect;
