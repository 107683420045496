import HotelIcon from '@mui/icons-material/Apartment';
import CruiseIcon from '@mui/icons-material/DirectionsBoat';
import CarIcon from '@mui/icons-material/DirectionsCar';
import TourIcon from '@mui/icons-material/Explore';
import FlightIcon from '@mui/icons-material/Flight';
import InsuranceIcon from '@mui/icons-material/Luggage';
import StarIcon from '@mui/icons-material/Star';
import TrainIcon from '@mui/icons-material/Train';
import OtherIcon from '@mui/icons-material/Widgets';
import VirtuosoIcon from 'components/common/svgs/Virtuoso';
import { type ClientInvoiceBookingType, SupplierType } from 'dtos';

export const getSupplierTypeName = (
  type: SupplierType,
): ClientInvoiceBookingType => {
  switch (type) {
    case SupplierType.AIRLINE:
      return 'Airline';
    case SupplierType.CRUISE_LINE:
      return 'Cruise Line';
    case SupplierType.HOTEL:
      return 'Hotel';
    case SupplierType.INSURANCE:
      return 'Insurance';
    case SupplierType.RAIL:
      return 'Rail';
    case SupplierType.TOUR_DMC:
      return 'Tour DMC';
    case SupplierType.TRANSPORTATION:
      return 'Transportation';
    case SupplierType.OTHER:
      return 'Other';
    case SupplierType.ANCILLARIES:
      return 'Ancillaries';
  }
};

export const getSupplierIconByType = (type: SupplierType | null) => {
  switch (type) {
    case 'AIRLINE':
      return <FlightIcon />;
    case 'CRUISE_LINE':
      return <CruiseIcon />;
    case 'HOTEL':
      return <HotelIcon />;
    case 'INSURANCE':
      return <InsuranceIcon />;
    case 'RAIL':
      return <TrainIcon />;
    case 'TOUR_DMC':
      return <TourIcon />;
    case 'TRANSPORTATION':
      return <CarIcon />;
    case 'ANCILLARIES':
      return <OtherIcon />;
    case 'OTHER':
      return <OtherIcon />;
    default:
      return undefined;
  }
};

export const getSupplierBadgeIconByName = (icon: string | undefined) => {
  switch (icon) {
    case 'star':
      return <StarIcon />;
    case 'virtuoso':
      return <VirtuosoIcon />;
    default:
      return undefined;
  }
};
