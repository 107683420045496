import type { SxProps, Theme } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import type { CreatableListItem, ListItem } from 'components/types';
import { useField, useFormikContext } from 'formik';
import { useUserAgencies } from 'hooks';
import { useEffect, useState } from 'react';
import { getError } from 'utils/client/formik';
import type { FormikMetaWithError } from 'utils/client/types';

const filter = createFilterOptions<ListItem | CreatableListItem>();

type AgencySelectorProps<T extends string = string> = {
  disabled?: boolean;
  helperText?: React.ReactNode;
  idField?: string;
  label: string;
  name: T;
  onChange?: (value: ListItem | CreatableListItem | null) => void;
  required?: boolean;
  sx?: SxProps<Theme> | undefined;
  tripAdvisorUserId?: string;
};

export default function AgencySelector<T extends string = string>({
  name,
  label,
  required,
  tripAdvisorUserId,
  onChange,
  idField = 'id',
  sx,
  disabled = false,
  helperText,
}: AgencySelectorProps<T>) {
  const [field, meta, helpers] = useField<ListItem>(name);
  const { submitCount } = useFormikContext();

  const [options, setOptions] = useState<
    (ListItem | (CreatableListItem & { inputValue?: string }))[]
  >([]);

  const { agencies, isLoading } = useUserAgencies({
    getAll: true,
    sortModel: { sort: 'lastUsed', sortDirection: 'desc' },
    userId: tripAdvisorUserId,
  });

  useEffect(() => {
    if (isLoading) return;

    setOptions(
      agencies.map((agency) => ({
        id: agency.id,
        name: agency.name,
      })),
    );
  }, [isLoading]);

  const errorOrHelperText =
    (submitCount > 0 && getError(meta as FormikMetaWithError, idField)) ||
    helperText;

  return (
    <Autocomplete
      {...field}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disabled={disabled}
      fullWidth
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      sx={sx}
      options={options}
      freeSolo={false}
      onChange={(_event, selectedAgency) => {
        const agency = selectedAgency as CreatableListItem;

        if (onChange) {
          onChange(agency || null);
        } else {
          helpers.setValue((selectedAgency as ListItem) || null);
        }
      }}
      filterOptions={filter}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if ((option as CreatableListItem).inputValue) {
          return (option as CreatableListItem).inputValue;
        }
        // Regular option
        return option.name || '';
      }}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          helperText={errorOrHelperText}
          InputProps={params.InputProps}
          label={label}
          required={required}
          onFocus={() => {
            helpers.setTouched(true);
          }}
          onBlur={() => {
            helpers.setTouched(false);
          }}
          error={
            submitCount > 0 && !!getError(meta as FormikMetaWithError, idField)
          }
        />
      )}
    />
  );
}
