import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import type { SxProps, Theme } from '@mui/system';
import { useField, useFormikContext } from 'formik';
import type React from 'react';

type Option = string | { label: string; value: string };

type ComboBoxExtendedProps<T extends string = string> = {
  name: T;
  label: string;
  placeholder?: string;
  options: Option[];
  multiple?: boolean;
  required?: boolean;
  sx?: SxProps<Theme> | undefined;
  fullWidth?: boolean;
};

// TODO: this should eventually replace ComboBox b/c it should handle all the cases ComboBox does
// as well as
const ComboBoxExtended = <T extends string>({
  name,
  label,
  placeholder,
  required,
  options,
  sx,
  multiple,
  fullWidth,
}: ComboBoxExtendedProps<T>) => {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  const { submitCount } = useFormikContext();

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (multiple) {
      if (!Array.isArray(field.value)) {
        setValue([event.target.value]);
      } else if (
        !field.value.includes(event.target.value) &&
        event.target.value?.trim()
      ) {
        setValue([...field.value, event.target.value]);
      }
    } else {
      setValue(event.target.value);
    }

    field.onBlur(event);
  };

  const getOptionLabel = (option: Option): string => {
    if (typeof option === 'string') return option;
    return option.label;
  };

  const getValue = (option: Option): string => {
    if (typeof option === 'string') return option;
    return option.value;
  };

  const handleChange = (_: unknown, newValue: Option | Option[] | null) => {
    if (!newValue) {
      setValue('');
      return;
    }

    if (Array.isArray(newValue)) {
      // Handle array of values (multiple select)
      const values = newValue.map((val) =>
        typeof val === 'string'
          ? val
          : options.find((o) => getValue(o) === val.value),
      );
      setValue(values);
    } else {
      // Handle single value
      const value =
        typeof newValue === 'string'
          ? newValue
          : options.find((o) => getValue(o) === newValue.value);
      setValue(value);
    }
  };

  return (
    <Autocomplete
      {...field}
      sx={sx}
      onChange={handleChange}
      onInputChange={(event, newInputValue) => {
        if (multiple && event.target instanceof HTMLInputElement) {
          if (newInputValue.endsWith(',')) {
            event.target.value = newInputValue.slice(0, -1);
            event.target.blur();
            event.target.focus();
          }
        }
      }}
      freeSolo
      multiple={multiple}
      selectOnFocus
      fullWidth={fullWidth}
      clearOnBlur={multiple}
      onBlur={handleBlur}
      handleHomeEndKeys
      options={options}
      getOptionLabel={getOptionLabel}
      renderOption={(props, option) => (
        <li {...props} key={typeof option === 'string' ? option : option.value}>
          {getOptionLabel(option)}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={
            (multiple && field.value.length === 0) ||
            (!multiple && !field.value)
              ? placeholder
              : ''
          }
          id={name}
          name={name}
          required={required}
          error={submitCount > 0 && !!meta.error}
          helperText={submitCount > 0 && meta.error}
        />
      )}
    />
  );
};

export default ComboBoxExtended;
