import {
  Checkbox,
  PercentTextField,
  PhoneField,
  TagMultiselect,
  TextField,
} from '@customFormik';
import { Link, Pin } from '@mui/icons-material';
import {
  FormControl,
  FormControlLabel,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import DueDateButtons from 'components/bookings/DueDateButtons';
import SupplierBadgeMultiselect from 'components/common/forms/formik/SupplierBadgeMultiselect';
import SupplierMultiselect from 'components/common/forms/formik/SupplierMultiselect';
import SupplierSelector from 'components/common/forms/formik/SupplierSelector';
import SupplierTypeSelector from 'components/common/forms/formik/SupplierTypeSelector';
import { TagType } from 'dtos';
import { useFormikContext } from 'formik';
import { useUserInfo } from 'hooks';
import moment from 'moment-timezone';
import type { SupplierFormValues } from './schema';

const today = moment();

type SupplierFormProps = {
  isCustom?: boolean;
  mode?: 'add' | 'edit';
};

export default function SupplierForm({
  isCustom,
  mode = 'add',
}: SupplierFormProps) {
  const {
    values: fv,
    setFieldValue,
    errors,
  } = useFormikContext<SupplierFormValues>();

  const { userInfo } = useUserInfo();

  const isDisabled =
    mode === 'edit' && (isCustom ? !userInfo?.isOrgUser : true);

  const isEditable = (allowFor: 'customOnly' | 'customAndManaged') => {
    if (mode === 'add') return true;
    return userInfo.isOrgUser && (isCustom || allowFor === 'customAndManaged');
  };

  return (
    <Stack sx={{ gap: 2 }} component="form" autoComplete="off">
      <TextField
        name="name"
        label="Name"
        required
        fullWidth
        disabled={!isEditable('customOnly')}
      />
      <TextField
        name="address"
        label="Address"
        fullWidth
        autoComplete="random-string-to-prevent-autofill"
        disabled={!isEditable('customOnly')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Pin />
            </InputAdornment>
          ),
        }}
      />
      <FormControl fullWidth>
        <PhoneField
          name="phone"
          label="Phone"
          defaultCountry="US"
          autoCorrect="on"
          disabled={!isEditable('customOnly')}
        />
      </FormControl>
      <TextField
        name="website"
        label="Website"
        fullWidth
        disabled={!isEditable('customOnly')}
        inputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Link />
            </InputAdornment>
          ),
        }}
      />
      <FormControl fullWidth>
        <SupplierTypeSelector
          name="type"
          label="Supplier Type"
          disabled={!isEditable('customOnly')}
        />
      </FormControl>

      <FormControlLabel
        control={
          <Checkbox
            name={'isClearinghouse'}
            disabled={!isEditable('customOnly')}
          />
        }
        label="Clearinghouse"
        disabled={!isEditable('customOnly')}
      />

      <SupplierBadgeMultiselect
        name="badges"
        label="Badges"
        disabled={!isEditable('customAndManaged')}
      />

      <TagMultiselect
        name="tags"
        label="Tags"
        type={TagType.SUPPLIER}
        disabled={!isEditable('customAndManaged')}
        allowAdd={true}
      />

      <SupplierMultiselect
        name="associatedSuppliers"
        label="Associated Suppliers"
        disabled={!isEditable('customAndManaged')}
      />

      <SupplierSelector
        name="parent"
        label="Parent Supplier"
        allowAdd
        disabled={!isEditable('customOnly')}
      />

      {userInfo?.organization.isConnectedToGds &&
        (mode === 'edit' || userInfo?.isOrgUser) && (
          <TextField
            name="interfaceId"
            label="Interface ID"
            fullWidth
            disabled={isDisabled || !userInfo?.isOrgUser}
          />
        )}

      <PercentTextField
        name="defaultCommissionPercent"
        label="Commission %"
        disabled={!isEditable('customAndManaged')}
        fullWidth
      />
      <FormControl>
        <Typography
          variant="caption"
          color={
            errors.defaultCommissionDueDateOffset ? 'error' : 'text.secondary'
          }
        >
          Commission Due:
        </Typography>
        <DueDateButtons
          referenceDate={today.toDate()}
          value={today.add(fv.defaultCommissionDueDateOffset, 'days').toDate()}
          onChange={(days) =>
            setFieldValue('defaultCommissionDueDateOffset', days)
          }
          disabled={!isEditable('customAndManaged')}
        />
        {errors.defaultCommissionDueDateOffset && (
          <Typography
            sx={{ mt: '3px', ml: '0.875rem' }}
            variant="caption"
            color="error"
          >
            {errors.defaultCommissionDueDateOffset}
          </Typography>
        )}
      </FormControl>
    </Stack>
  );
}
