import type { ClientPaymentMethodResponseDto } from 'dtos';
import useSWR from 'swr';
import { fetcher } from 'utils/client/swr/jsonFetcher';

type UseClientPaymentMethodsResponse = {
  data: ClientPaymentMethodResponseDto[];
  isLoading: boolean;
  error: Error | undefined;
  refresh: () => void;
};

type UseClientPaymentMethodsProps = {
  clientId?: string;
  clientInvoiceId?: string;
};

export default function useClientPaymentMethods({
  clientId,
  clientInvoiceId,
}: UseClientPaymentMethodsProps): UseClientPaymentMethodsResponse {
  const query = new URLSearchParams({
    ...(clientId && { clientId }),
    ...(clientInvoiceId && { clientInvoiceId }),
  }).toString();
  const endpoint = `client-payment-methods${query ? '?' : ''}${query}`;
  const {
    data: response,
    isValidating: isLoading,
    error,
    mutate,
  } = useSWR<{ data: ClientPaymentMethodResponseDto[] }, Error>(
    endpoint,
    fetcher,
  );

  return {
    data: response?.data || [],
    isLoading,
    error,
    refresh: () => void mutate(),
  };
}
