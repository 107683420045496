import useAdvisorExpenseCategories from 'hooks/useAdvisorExpenseCategories';
import React from 'react';
import Select, { type SelectProps } from './Select';

type AdvisorExpenseCategorySelectorProps<T extends string = string> = Omit<
  SelectProps,
  'options'
> & {
  name: T;
};
export default function AdvisorExpenseCategorySelector<
  T extends string = string,
>({ name, ...props }: AdvisorExpenseCategorySelectorProps<T>) {
  const { data: options } = useAdvisorExpenseCategories();

  if (!options) return null;

  return (
    <Select
      name={name}
      {...props}
      options={options.map((o) => ({
        label: o.name,
        value: o.id,
      }))}
    />
  );
}
