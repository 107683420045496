import { PayingEntity } from 'dtos';
import React from 'react';
import Select, { type SelectProps } from './Select';

type PaymentTypeSelectorProps<T extends string = string> = Omit<
  SelectProps,
  'options'
> & {
  name: T;
};
export default function PaymentTypeSelector<T extends string = string>({
  name,
  ...props
}: PaymentTypeSelectorProps<T>) {
  const options = [
    { id: PayingEntity.AGENCY, name: 'Agency Paid' },
    { id: PayingEntity.CLIENT, name: 'Client Paid' },
  ];

  return (
    <Select
      name={name}
      {...props}
      options={options.map((o) => ({
        label: o.name,
        value: o.id,
      }))}
    />
  );
}
