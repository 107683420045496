import { type AutocompleteRenderInputParams, TextField } from '@mui/material';
import type { ListItem } from 'components/types';
import { type FieldMetaProps, useFormikContext } from 'formik';
import { getError } from 'utils/client/formik';
import type { FormikMetaWithError } from 'utils/client/types';

type ClientMultiselectInputFieldProps = {
  params: AutocompleteRenderInputParams;
  label: string;
  helperText: React.ReactNode;
  fieldId: string;
  required: boolean;
  meta: FieldMetaProps<ListItem[]>;
  onFocus: () => void;
  onBlur: () => void;
};

const ClientMultiselectInputField = ({
  params,
  label,
  helperText,
  fieldId,
  required,
  meta,
  onFocus,
  onBlur,
}: ClientMultiselectInputFieldProps) => {
  const { submitCount } = useFormikContext();

  const errorText = getError(meta as FormikMetaWithError, fieldId);

  const errorOrHelperText =
    submitCount > 0 && errorText ? errorText : helperText;

  return (
    <TextField
      {...params}
      helperText={errorOrHelperText}
      label={label}
      required={required}
      onFocus={onFocus}
      onBlur={onBlur}
      error={submitCount > 0 && !!errorText}
    />
  );
};

export default ClientMultiselectInputField;
