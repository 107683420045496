import { type SxProps, type Theme, debounce } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import type { CreatableListItem, ListItem } from 'components/types';
import type { GroupResponseDto } from 'dtos';
import { useField, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { getCorporateGroups } from 'requests/groups';

// TODO: Allow Additions
type CorporateGroupSelectorProps<T extends string = string> = {
  name: T;
  label: string;
  required?: boolean;
  // allowAdd: boolean;
  // onAddSuccess: (newSupplier: SupplierResponseDto) => void;
  onChange?: (value: ListItem | CreatableListItem | null) => void;
  sx?: SxProps<Theme> | undefined;
  disabled?: boolean;
  clientId?: string;
};

type FieldType = {
  id: string;
  name: string;
};

export default function CorporateGroupSelector<T extends string = string>({
  name,
  label,
  required,
  // allowAdd,
  // onAddSuccess,
  onChange,
  sx,
  disabled = false,
  clientId,
}: CorporateGroupSelectorProps<T>) {
  const [field, meta, helpers] = useField<FieldType>(name);
  const { submitCount } = useFormikContext();

  const [options, setOptions] = useState<
    (ListItem | (CreatableListItem & { inputValue?: string }))[]
  >([]);

  // const [addStatus, setAddStatus] = useQueryStates({
  //   addingSupplier: queryTypes.boolean.withDefault(false),
  //   supplierName: queryTypes.string,
  // });

  useEffect(() => {
    getCorporateGroups({ clientId }).then((newOptions) =>
      setOptions(newOptions),
    );
  }, [clientId]);

  const debouncedGetCorporateGroups = debounce(async (query: string) => {
    const newOptions = await getCorporateGroups({
      query: query || '',
      pageSize: 500,
      clientId,
    });

    setOptions(
      newOptions.map((opt: GroupResponseDto) => ({
        id: opt.id,
        name: opt.name,
      })),
    );
  }, 200);

  return (
    <>
      <Autocomplete
        {...field}
        disabled={disabled}
        fullWidth
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        sx={sx}
        options={options}
        // freeSolo={allowAdd}
        freeSolo={false}
        onInputChange={async (_event, newInputValue) =>
          await debouncedGetCorporateGroups(newInputValue)
        }
        onChange={(_event, newCorporateGroup) => {
          const group = newCorporateGroup as CreatableListItem;

          if (group?.inputValue) {
            // void setAddStatus({
            //   addingSupplier: true,
            //   supplierName: supplier.inputValue,
            // });
          } else {
            onChange?.(group);
          }

          helpers.setValue(newCorporateGroup as FieldType);
        }}
        // filterOptions={(options, params) => {
        //   const { inputValue } = params;

        //   // Suggest the creation of a new value
        //   const isExisting = options.some(
        //     (option) => inputValue === option.name,
        //   );

        //   const newOptions = [...options];

        //   if (inputValue !== '' && !isExisting) {
        //     newOptions.push({
        //       id: '',
        //       inputValue,
        //       name: `"${inputValue}" not found `,
        //     });
        //   }

        //   return newOptions;
        // }}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if ((option as CreatableListItem).inputValue) {
            return (option as CreatableListItem).inputValue;
          }
          // Regular option
          return option.name as string;
        }}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={params.InputProps}
            onFocus={() => {
              helpers.setTouched(true);
            }}
            onBlur={() => {
              helpers.setTouched(false);
            }}
            label={label}
            error={submitCount > 0 && !!meta.error}
            helperText={submitCount > 0 && meta.error}
            required={required}
          />
        )}
      />
      {/* {isDialogOpen && (
        <SupplierDialog
          mode='add'
          supplier={{
            name: addStatus.supplierName as string,
            type: SupplierType.HOTEL,
          }}
          open={isDialogOpen}
          onClose={() =>
            setAddStatus({
              addingSupplier: null,
              supplierName: null,
            })
          }
          onSuccess={(_mode, supplier: SupplierResponseDto) => {
            void onAddSuccess(supplier);
            void setAddStatus({ addingSupplier: null, supplierName: null });
          }}
        />
      )} */}
    </>
  );
}
